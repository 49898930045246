import React, {useState } from "react";
import { TransactionListContext } from "../../context/transactionListContext.js";
import TransactionList from "./transaction";

function ViewTransactions() {
  const [transactions, setTransactions] = useState([]);
  const value = { transactions, setTransactions };

  return (
    <TransactionListContext.Provider value={value}>
      <TransactionList />
    </TransactionListContext.Provider>
  );
}

export default ViewTransactions;
