import RedirectLogin from "../helper/redirectLogin";

const apiUrl = process.env.REACT_APP_BASE_URL;

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${
    localStorage.getItem("adminToken") &&
    localStorage.getItem("adminToken").split('"')[1]
  }`,
};

export const getReviewsList = async (limit, status, id) => {
  var requestOptions = {
    method: "GET",
    headers,
  };
  try {
    let url = `${apiUrl}/admin/reviews?limit=${limit}${
      status !== undefined ? `&&q=${status}` : ""
    }`;
    if (id) {
      url = `${url}&&id=${id}`;
    }
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

// APPROVED , REJECTED
export const decideReview = async (id, action, isEdited) => {
  var requestOptions = {
    method: "PUT",
    headers,
  };
  try {
    let url = `${apiUrl}/admin/reviews/${id}?q=${action}&&isEdit=${isEdited}`;

    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};
