import RedirectLogin from "../helper/redirectLogin";

const apiUrl = process.env.REACT_APP_BASE_URL;

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${
    localStorage.getItem("adminToken") &&
    localStorage.getItem("adminToken").split('"')[1]
  }`,
};

const getAllUser = async (limit, id) => {
  var requestOptions = {
    method: "GET",
    headers,
  };
  try {
    let url = `${apiUrl}/admin/profiles?limit=${limit}`;
    if (id) {
      url = `${url}&&id=${id}`;
    }
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getUserProfile = async (id) => {
  var requestOptions = {
    method: "GET",
    headers,
  };
  try {
    const url = `${apiUrl}/admin/profiles/${id}`;

    const response = await fetch(url, requestOptions);

    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getRequestList = async (id) => {
  var requestOptions = {
    method: "GET",
    headers,
  };
  try {
    const url = `${apiUrl}/admin/profiles/${id}/requests`;

    const response = await fetch(url, requestOptions);

    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getFriendList = async (id) => {
  var requestOptions = {
    method: "GET",
    headers,
  };
  try {
    const url = `${apiUrl}/admin/profiles/${id}/friends`;

    const response = await fetch(url, requestOptions);

    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getUserLocations = async () => {
  var requestOptions = {
    method: "GET",
    headers,
  };
  try {
    const url = `${apiUrl}/admin/locations/profiles?limit=124`;

    const response = await fetch(url, requestOptions);

    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getToken = async (values) => {
  var requestOptions = {
    method: "POST",
    body: values,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const url = `${apiUrl}/auth/admin/login`;
    const response = await fetch(url, requestOptions);

    //get token from response
    const token = await response.json();
    if (response.status === 200) {
      await localStorage.setItem(
        "adminToken",
        JSON.stringify(token.data.access_token)
      );
      window.location.href = "/charts";
    }

    return token;
  } catch (error) {
    console.log(error);
  }
};

export {
  getAllUser,
  getUserProfile,
  getRequestList,
  getFriendList,
  getUserLocations,
  getToken,
};
