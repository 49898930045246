import React from "react";
import PeopleIcon from "@mui/icons-material/People";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventIcon from "@mui/icons-material/Event";
import MapIcon from "@mui/icons-material/Map";
import ReviewsIcon from "@mui/icons-material/Reviews";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import BugReportIcon from "@mui/icons-material/BugReport";
import StarIcon from "@mui/icons-material/Star";
import PaidIcon from "@mui/icons-material/Paid";
const navigationConfigFirst = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <DashboardIcon />,
    // child: [
    //   {
    //     id: "homadase",
    //     title: "Dashboardd",
    //     type: "item",
    //     icon: <DashboardIcon />,

    //     navLink: "/",
    //   },
    //   {
    //     id: "dark",
    //     title: "dark",
    //     type: "item",
    //     icon: <DashboardIcon />,

    //     navLink: "/",
    //   },
    // ],
    navLink: "/charts",
  },
  {
    id: "userList",
    title: "User List",
    type: "item",
    icon: <PeopleIcon />,
    navLink: "/userList",
  },
  {
    id: "phenomenonList",
    title: "Phenomenon List ",
    type: "item",
    icon: <StarIcon />,
    navLink: "/phenomenons",
  },
  {
    id: "bugsList",
    title: "Bugs List ",
    type: "item",
    icon: <BugReportIcon />,
    navLink: "/bugs",
  },
  {
    id: "transactionList",
    title: "Transaction List",
    type: "item",
    icon: <PaidIcon />,
    navLink: "/transaction",
  },

  {
    id: "reports",
    title: "Reports",
    type: "item",
    icon: <DnsRoundedIcon />,
    navLink: "/reports",
  },

  {
    id: "reviewList",
    title: "Review List",
    type: "item",
    icon: <ReviewsIcon />,
    navLink: "/reviewList",
  },

  {
    id: "events",
    title: "Event List",
    type: "item",
    icon: <EventIcon />,
    navLink: "/events",
  },

  {
    id: "createPhenomenon",
    title: "Create Phenomenon",
    type: "item",
    icon: <PersonAddAltIcon />,
    navLink: "/createPhenomen",
  },

  {
    id: "maps",
    title: "Map",
    type: "item",
    icon: <MapIcon />,
    navLink: "/map",
  },
];

export { navigationConfigFirst };
