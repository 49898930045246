import { TableHead, TableBody, TableRow, Table, Skeleton } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { TablePagination } from "@mui/material";
import { useNavigate } from "react-router";
import AvatarComponent from "./avatarComponent";
import GroupAvatarComponent from "./groupAvatarComponent";
import { getFullDateAndHour } from "../../helper/dateConvert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ModalComponent from "./modalComponent";
import { useState } from "react";
import ToolTipComponent from "./toolTipComponent";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LockIcon from "@mui/icons-material/Lock";

import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function TableComponent({
  tableHeaderNames,
  tableBody,
  totalCount,
  limit,
  page,
  setPage,
  getUsers,
  updateAction,
  setCurrentId,
  hiddenId,
  disablePagination,
  tableLoading,
  prevTableBody,
}) {
  const priorityList = {
    LOW: "#00ff00",
    MEDIUM: "#ffa500",
    HIGH: "#ff0000",
  };

  const bugsStatus = {
    OPEN: "#30c03c",
    CLOSED: "#ff0000",
  };

  const ShimmerColor = "#0000001c";
  const [openModal, setOpenModal] = useState("");
  const [text, setText] = useState("");
  const [images, setImages] = useState([]);

  const [clicked, setClicked] = useState(false);
  const history = useNavigate();

  const handleClick = (id, action) => {
    setCurrentId(id);
  };

  if (tableBody.length > 0) {
    var keyValues = Object.keys(tableBody[0]);
  }

  const TableHeadRows = () => {
    return tableHeaderNames.map((headerNames, i) => {
      return <TableCell key={i}>{headerNames}</TableCell>;
    });
  };

  const TableBodyRows = () => {
    return tableBody.map((profile) => {
      return (
        <TableRow key={profile._id}>
          {keyValues.map((keys) => {
            if (keys === "reporter" || keys === "reported") {
              return (
                <TableCell key={keys}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gridColumnGap: "10px",
                    }}
                  >
                    <AvatarComponent
                      avatarImage={profile[keys]?.profileImages?.murl}
                      disabled
                    />
                    <span
                      style={{ cursor: "pointer", color: "green" }}
                      onClick={() => history(`/profile/${profile[keys]._id}`)}
                    >
                      {profile[keys].username}
                    </span>
                  </div>
                </TableCell>
              );
            }

            if (keys === "reasons") {
              return (
                <TableCell key={keys}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gridColumnGap: "10px",
                    }}
                  >
                    {profile[keys].length < 1 || profile[keys] === undefined
                      ? "-"
                      : profile[keys].map(({ reason }) => {
                          return <span> {reason}</span>;
                        })}
                  </div>
                </TableCell>
              );
            }

            if (keys === "review") {
              return (
                <TableCell key={keys}>
                  {!profile[keys] ? (
                    "- "
                  ) : (
                    <>
                      <GroupAvatarComponent
                        avatarData={profile[keys].images}
                      ></GroupAvatarComponent>
                      <span>{profile[keys].comment}</span>
                    </>
                  )}
                </TableCell>
              );
            }
            if (keys === "status") {
              return (
                <TableCell key={keys}>
                  {profile[keys] ? (
                    <span style={{ color: bugsStatus[profile[keys]] }}>
                      {profile[keys]}{" "}
                    </span>
                  ) : (
                    "-"
                  )}
                </TableCell>
              );
            }
            if (keys === "admin") {
              return (
                <TableCell key={keys}>
                  {profile[keys] ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {tableLoading ? (
                        <Skeleton variant="text" width={"100%"} height={40} />
                      ) : (
                        <>
                          <ToolTipComponent
                            icon={
                              <AccountCircleIcon sx={{ fontSize: "40px" }} />
                            }
                            title={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div>{profile[keys].name}</div>
                                <div>{profile[keys].phone}</div>
                                <div>{profile[keys].email}</div>
                                <div>{profile[keys].roles}</div>
                              </div>
                            }
                          />

                          <span>{profile[keys].name}</span>
                        </>
                      )}
                    </div>
                  ) : (
                    "-"
                  )}
                </TableCell>
              );
            }

            if (keys === "gender") {
              return (
                <TableCell key={keys} width={50}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {tableLoading ? (
                      <ManIcon style={{ color: ShimmerColor }} />
                    ) : (
                      <ToolTipComponent
                        title={profile[keys] === "M" ? "MAN" : "WOMAN"}
                        icon={
                          profile[keys] === "M" ? <ManIcon /> : <WomanIcon />
                        }
                        iconStyle={{
                          color: profile[keys] === "M" ? "#0095ff" : "pink",
                        }}
                      ></ToolTipComponent>
                    )}
                  </div>
                </TableCell>
              );
            }

            if (keys === "profileImage") {
              return (
                <TableCell key={keys}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {tableLoading ? (
                      <Skeleton
                        variant="circular"
                        width={40}
                        height={40}
                        style={{ marginTop: "10px" }}
                      />
                    ) : (
                      <AvatarComponent avatarImage={profile[keys]} disabled />
                    )}
                  </div>
                </TableCell>
              );
            }
            if (keys === "createdAt" || keys === "deletedAt") {
              return (
                <TableCell key={keys}>
                  {tableLoading ? (
                    <Skeleton variant="text" width={"100%"} height={40} />
                  ) : profile[keys] === undefined || profile[keys] === null ? (
                    "-"
                  ) : (
                    getFullDateAndHour(profile[keys])
                  )}
                </TableCell>
              );
            }

            if (keys === "_id") {
              return (
                <TableCell
                  style={{
                    display: hiddenId ? "none" : "",
                  }}
                  width={200}
                  key={keys}
                >
                  {tableLoading ? (
                    <Skeleton variant="text" width={"100%"} height={40} />
                  ) : (
                    <span
                      style={{ color: "#1876c5", cursor: "pointer" }}
                      onMouseLeave={() => setClicked(false)}
                      onClick={() => {
                        navigator.clipboard.writeText(profile[keys]);
                        setClicked(true);
                      }}
                    >
                      <ToolTipComponent
                        placement={"top"}
                        title={clicked ? "Copied !" : "Click To Copy Board"}
                        icon={`${profile[keys]} `}
                      ></ToolTipComponent>
                    </span>
                  )}
                </TableCell>
              );
            }

            if (keys === "isDeleted") {
              return (
                <TableCell key={keys} width={50}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {tableLoading ? (
                      <RemoveCircleOutlineIcon
                        style={{ color: ShimmerColor }}
                      />
                    ) : (
                      <ToolTipComponent
                        title={
                          profile[keys] ? "Deleted Account" : "Valid Account"
                        }
                        icon={
                          profile[keys] ? (
                            <RemoveCircleOutlineIcon style={{ color: "red" }} />
                          ) : (
                            "-"
                          )
                        }
                      ></ToolTipComponent>
                    )}
                  </div>
                </TableCell>
              );
            }

            if (keys === "isPrivate") {
              return (
                <TableCell key={keys} width={50}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {tableLoading ? (
                      <LockIcon style={{ color: ShimmerColor }} />
                    ) : (
                      <ToolTipComponent
                        title={
                          profile[keys] ? "Private Account" : "Public Account"
                        }
                        icon={profile[keys] ? <LockIcon /> : "-"}
                      ></ToolTipComponent>
                    )}
                  </div>
                </TableCell>
              );
            }

            if (keys === "username") {
              return (
                <TableCell key={keys} width={150}>
                  {tableLoading ? (
                    <Skeleton variant="text" width={"100%"} height={40} />
                  ) : profile[keys] ? (
                    <span
                      onClick={() => history(`/profile/${profile["_id"]}`)}
                      style={{ color: "green", cursor: "pointer" }}
                    >
                      {profile[keys]}
                    </span>
                  ) : (
                    "-"
                  )}
                </TableCell>
              );
            }

            if (keys === "name" || keys === "surname") {
              return keys === "surname" ? (
                ""
              ) : (
                <TableCell key={keys} width={200}>
                  {tableLoading ? (
                    <Skeleton variant="text" width={"100%"} height={40} />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span>{profile["name"]}</span>
                      <span> {profile["surname"]}</span>
                    </div>
                  )}
                </TableCell>
              );
            }

            if (keys === "action") {
              return (
                <TableCell key={keys}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {!profile[keys].deletedAt ? (
                      <DeleteForeverIcon
                        id={profile[keys].phenomenon._id}
                        key={profile[keys].phenomenon._id}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleClick(profile[keys].phenomenon._id, "DELETED")
                        }
                      />
                    ) : (
                      ""
                    )}

                    <BorderColorIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => updateAction(profile[keys])}
                    />
                  </div>
                </TableCell>
              );
            }

            if (keys === "phenomenon") {
              return (
                <TableCell key={keys}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {tableLoading ? (
                      <Skeleton variant="text" width={"100%"} height={40} />
                    ) : (
                      <>
                        <AvatarComponent
                          avatarImage={
                            profile[keys]?.profileImages
                              ? profile[keys]?.profileImages[0]?.surl || ""
                              : profile[keys].profileImage ||  ""
                          }
                          disabled
                        ></AvatarComponent>
                        <span
                          onClick={() =>
                            history(`/profile/${profile[keys]?._id}`)
                          }
                          style={{ color: "green", cursor: "pointer" }}
                        >
                          {profile[keys]?.username}
                        </span>
                      </>
                    )}
                  </div>
                </TableCell>
              );
            }

            if (keys === "bugImages") {
              return (
                <TableCell key={keys}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {profile[keys] === undefined ||
                    profile[keys].length <= 0 ? (
                      "-"
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setImages(profile[keys].map(({ url }) => url));
                          setOpenModal("IMAGE");
                        }}
                      >
                        <GroupAvatarComponent
                          custom={{
                            ".MuiAvatar-root": {
                              width: 44,
                              height: 44,
                              fontSize: 14,
                              border: "1px solid",
                            },
                          }}
                          avatarData={profile[keys].map(({ surl }) => {
                            return { profileImage: surl };
                          })}
                          disabled
                          max={1}
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
              );
            }

            if (keys === "priority") {
              return (
                <TableCell key={keys}>
                  {profile[keys] ? (
                    <div>
                      <ToolTipComponent
                        icon={
                          <div
                            style={{
                              height: "20px",
                              width: "20px",
                              borderRadius: "50%",
                              content: " ",
                              background: priorityList[profile[keys]],
                            }}
                          />
                        }
                        id={keys}
                        title={profile[keys]}
                      ></ToolTipComponent>
                    </div>
                  ) : (
                    "-"
                  )}
                </TableCell>
              );
            }
            return (
              <TableCell
                key={keys}
                onClick={() => {
                  if (profile[keys] && profile[keys].length > 30) {
                    setText(profile[keys]);
                    setOpenModal("TEXT");
                  }
                }}
                style={{
                  cursor:
                    profile[keys] && profile[keys].length > 30
                      ? "pointer"
                      : "auto",
                  fontWeight:
                    profile[keys] && profile[keys].length > 30 ? "bolder" : "",
                }}
              >
                {tableLoading ? (
                  <Skeleton variant="text" width={"100%"} height={40} />
                ) : (
                  `${
                    profile[keys] === 0 || profile[keys]
                      ? profile[keys].length > 30
                        ? profile[keys].slice(0, 35) + "..."
                        : profile[keys]
                      : "-"
                  }`
                )}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  const handleChangePage = async (event, newPage) => {
    let _id = undefined;

    if (prevTableBody && prevTableBody.length > 0) {
      _id = prevTableBody[prevTableBody.length - 1]._id;
    } else {
      if (tableBody && tableBody.length > 0)
        _id = tableBody[tableBody.length - 1]._id;
    }
    setPage(newPage);
    return await getUsers(newPage, _id);
  };

  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeadRows />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableBodyRows />
        </TableBody>
        <TablePagination
          style={{ display: disablePagination ? "none" : "" }}
          count={totalCount}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[]}
          onPageChange={handleChangePage}
        ></TablePagination>
      </Table>
      <ModalComponent
        open={openModal === "" ? false : true}
        setOpen={() => setOpenModal("")}
        title={"Detail"}
        body={
          openModal === "TEXT" ? (
            <div
              style={{ padding: "0px 10px 19px 23px", wordBreak: "break-word" }}
            >
              {text}
            </div>
          ) : (
            <div
              style={{
                padding: "0px 10px 19px 23px",
                display: "flex",
                flexDirection: "row",
                overflowX: "scroll",
                gap: "20px",
              }}
            >
              {/* @Todo need a bar for redirect to image */}
              {images.map((data) => {
                return <img src={data} style={{ width: "100%" }} alt=" "></img>;
              })}
            </div>
          )
        }
      ></ModalComponent>
    </>
  );
}

export default TableComponent;
