/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createBugs, deleteBugs, getBugs, updateBugs } from "../../api/bugs";
import TableComponent from "../../components/custom/tableComponent";
import { BugsList, NextBugsList } from "../../context/bugsListContext";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ToolTipComponent from "../../components/custom/toolTipComponent";
import CreateOrUpdateBugs from "./createBugs";
import FilterBugs from "./filterBug";
import SnackBarsComponent from "../../components/custom/snackBarsComponent";
import ModalComponent from "../../components/custom/modalComponent";
import WarningIcon from "@mui/icons-material/Warning";
import { getPhenomenons } from "../../api/phenomenon";
import ButtonComponent from "../../components/custom/buttonComponent";
function BugsReportList() {
  const bugsList = BugsList();
  const setBugsList = NextBugsList();

  const [openMessage, setOpenMessage] = useState(false);

  const [files, setFiles] = useState([]);
  const [showImage, setShowImage] = useState(undefined);

  const [message, setMessage] = useState("");
  const [action, setAction] = useState("CREATE");
  const [filter, setFilter] = useState({
    search: "",
    deletedAt: false,
    status: "OPEN",
    priority: "ALL",
  });

  const [bugData, setBugData] = useState({
    UPDATE: {
      title: "",
      description: "",
      priority: "",
      phenomenon: "",
      bugImages: [],
      id: "",
    },
    CREATE: {
      title: "",
      description: "",
      priority: "",
      bugImages: [],
      phenomenon: "",
    },
  });

  const [tableBody, setTableBody] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(30);
  const [page, setPage] = useState(0);

  // Delete Button Information
  const [currentId, setCurrentId] = useState("");
  const [phenomenons, setPhenomenons] = useState([]);
  // useEffect(() => {
  //   if (filter) {
  //     getBugsData(page, filter);
  //   }
  // }, [filter]);

  useEffect(() => {
    getBugsData(page);
    (async () => {
      const response = await getPhenomenons(200);
      const phenomensData = response.data.phenomenons
        .filter(({ _id }) => !!_id)
        .map(({ _id }) => {
          return { value: _id._id, label: _id.username };
        });

      setPhenomenons(phenomensData);
    })();
  }, []);
  useEffect(() => {
    const tableBody = bugsList[page]
      ? bugsList[page].map(
          ({
            _id,
            description,
            title,
            priority,
            phenomenon,
            bugImages,
            deletedAt,
            status,
            createdAt,
          }) => {
            return {
              _id,
              phenomenon,
              title,
              description,
              status,
              priority,
              bugImages,
              deletedAt,
              createdAt,
              action: {
                _id,
                phenomenon,
                title,
                description,
                status,
                priority,
                deletedAt,
                createdAt,
              },
            };
          }
        )
      : [];

    setTableBody(tableBody);
  }, [page, bugsList.length, totalCount, openDrawer, currentId]);

  const getBugsData = async (page, filter, id) => {
    //Checking array data, is it include this arrays
    if (isPageInculude(page) && id) {
      return bugsList;
    }

    // Request Incoming data
    const data = await getBugs(limit, filter, id);
    let newBugsList = JSON.parse(JSON.stringify(bugsList));
    newBugsList[page] = data.bugs;

    setBugsList(newBugsList);
    setTotalCount(data.totalCount);
  };

  const isPageInculude = (pageValue) => {
    const calculateTotalPage = totalCount / limit;
    return bugsList[pageValue] || pageValue === calculateTotalPage;
  };

  const removeBug = async (bugsData) => {
    const response = await deleteBugs({ id: bugsData });
    await getBugsData(page, filter);
    setOpenMessage(true);
    if (response.statusCode <= 299) {
      setMessage("Bugs Deleted !");
      setCurrentId("");
    } else {
      setMessage("Something wrong !");
    }
  };

  // State Update
  const updateBug = async (value) => {
    setBugData({
      ...bugData,
      UPDATE: {
        title: value.title,
        description: value.description,
        priority: value.priority,
        phenomenon: value.phenomenon._id,
        id: value._id,
      },
    });
    setAction("UPDATE");
    setOpenDrawer(true);
  };

  //Confirm update or create
  const onConfirm = async (value) => {
    let response = null;
    if (value === "CREATE") {
      response = await createBugs(bugData[value], limit, filter);
    } else {
      response = await updateBugs(bugData[value], limit, filter);
    }
    if (response.statusCode <= 299) {
      await getBugsData(page, filter);
      setOpenDrawer(false);
      setOpenMessage(true);
      setBugData({
        ...bugData,
        [value]: {
          title: "",
          description: "",
          priority: "",
          phenomenon: "",
          bugImages: [],
        },
      });
      setFiles([]);
      setMessage(value === "CREATE" ? "Created !" : "Updated !");
    } else {
      setOpenMessage(true);
      setMessage("Phenomen Not Found");
    }
  };

  const tableHeaderNames = [
    "BugID",
    "Phenomenon",
    "Title",
    "Description",
    "Priority",
    "Status",
    "Bug Images",
    "Deleted At",
    "Created At",
    "Action",
  ];

  return (
    <Card>
      <FilterBugs
        filter={filter}
        setFilter={(value) => setFilter(value)}
        setPage={(value) => setPage(value)}
        getBugsData={(page, filter) => getBugsData(page, filter)}
        setTableBody={() => setBugsList([])}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "10px",
        }}
      >
        <ToolTipComponent
          icon={
            <AddCircleIcon
              style={{ fontSize: "32px", marginTop: "10px" }}
              onClick={() => {
                setOpenDrawer(true);
                setAction("CREATE");
              }}
            />
          }
          title={"Add new bugs"}
          placement="left"
        />
      </div>

      <CardContent sx={{ textAlign: "center", overflow: "scroll" }}>
        <TableComponent
          tableHeaderNames={tableHeaderNames}
          tableBody={tableBody}
          totalCount={totalCount}
          limit={limit}
          page={page}
          setPage={(page) => setPage(page)}
          getUsers={(newPage, id) => getBugsData(newPage, filter, id)}
          updateAction={(value) => updateBug(value)}
          setCurrentId={(value) => setCurrentId(value)}
          hiddenId={false}
        />
      </CardContent>

      <CreateOrUpdateBugs
        openDrawer={openDrawer}
        bugData={bugData}
        action={action}
        files={files}
        showImage={showImage}
        phenomenonsOptions={phenomenons}
        setFiles={(value) => setFiles(value)}
        setShowImage={(value) => setShowImage(value)}
        setOpenDrawer={(value) => setOpenDrawer(value)}
        setBugData={(value) => setBugData(value)}
        setMessage={(value) => setMessage(value)}
        onConfirm={(value) => onConfirm(value)}
      />

      <SnackBarsComponent
        open={openMessage}
        handleClose={() => setOpenMessage(false)}
        horizontal="right"
        vertical="top"
        message={message}
      />
      <ModalComponent
        title={
          <span style={{ display: "flex", gap: "10px" }}>
            <WarningIcon /> Warning
          </span>
        }
        body={
          <ButtonComponent
            description={"Do You want to delete ?"}
            button1Name={"Yes"}
            button2Name={"No"}
            onConfirm={() => removeBug(currentId)}
            onCancel={() => setCurrentId("")}
            customStyle={{ marginLeft: "auto", gap: "20px", display: "flex" }}
          />
        }
        open={currentId === "" ? false : true}
        setOpen={() => setCurrentId("")}
      ></ModalComponent>
    </Card>
  );
}

export default BugsReportList;
