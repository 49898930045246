import { Card, CardContent, Grid } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import CardMedia from "@mui/material/CardMedia";
import { Star } from "@mui/icons-material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import BoyIcon from "@mui/icons-material/Boy";
import GirlIcon from "@mui/icons-material/Girl";
import WcIcon from "@mui/icons-material/Wc";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PublicIcon from "@mui/icons-material/Public";
import TranslateIcon from "@mui/icons-material/Translate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";

import CountryComponent from "../../components/custom/countryComponent";
import GroupAvatarComponent from "../../components/custom/groupAvatarComponent";
import { getFullDateAndHour } from "../../helper/dateConvert";
import ToolTipComponent from "./toolTipComponent";

const genderStatus = {
  A: <WcIcon />,
  M: <BoyIcon />,
  F: <GirlIcon />,
};

const statusType = {
  PUBLIC: (
    <ToolTipComponent
      icon={<VisibilityIcon sx={{ mt: 0.5 }} />}
      title={"PUBLIC EVENT"}
    />
  ),
  PRIVATE: (
    <ToolTipComponent
      icon={<VisibilityOffIcon sx={{ mt: 0.5 }} />}
      title={"PUBLIC EVENT"}
    />
  ),
  FRIEND: (
    <ToolTipComponent
      icon={<AssignmentIndIcon sx={{ mt: 0.5 }} />}
      title={"FRIEND EVENT"}
    />
  ),
};

function EventCard({ tableBody, xs, sm, md, lg, userInfo }) {
  return tableBody.map(
    ({
      _id,
      description,
      eventImage,
      title,
      address,
      rating,
      owner,
      participants,
      capacityCount,
      type,
      languages,
      nationalities,
      startDate,
      endDate,
      gender,
    }) => {
      return (
        <Grid key={_id} item xs={xs} sm={sm} md={md} lg={lg}>
          <Card
            sx={{
              borderRadius: "20px",
              maxHeight: "780px",
              minHeight: "780px",
              overflowY: "scroll",
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  src={owner.profileImage || userInfo.profileImage}
                  aria-label="recipe"
                />
              }
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={owner.username || userInfo.username}
              subheader={`${getFullDateAndHour(
                startDate
              )} - ${getFullDateAndHour(endDate)}`}
            />
            <CardMedia
              component="img"
              height="200"
              image={eventImage}
              alt="Paella dish"
            />
            <CardHeader
              action={
                <Grid container>
                  <Star
                    sx={{
                      color: "yellow",
                    }}
                  />
                  {rating}
                </Grid>
              }
              title={<Grid container>{title}</Grid>}
              subheader={description}
            />

            <CardContent>
              <h4>Event Details</h4>
              <Typography paragraph>
                <Grid container>
                  <AccountCircleIcon sx={{ mr: 1 }} />
                  {genderStatus[gender]}
                </Grid>
              </Typography>

              <Typography paragraph>
                <Grid container>{statusType[type]}</Grid>
              </Typography>

              <Typography paragraph>
                <Grid container>
                  <TranslateIcon sx={{ mr: 1 }} />
                  <CountryComponent country={languages} />
                </Grid>
              </Typography>
              <Typography paragraph>
                <Grid container>
                  <PublicIcon sx={{ mr: 1 }} />
                  <CountryComponent country={nationalities} />
                </Grid>
              </Typography>
              <Typography paragraph>
                <Grid container>
                  <PeopleIcon sx={{ mr: 1 }} /> {participants.length} Available
                  out of {capacityCount}
                  <GroupAvatarComponent
                    custom={{
                      marginLeft: "auto",
                      ".MuiAvatar-root": {
                        width: 24,
                        height: 24,
                        fontSize: 14,
                      },
                    }}
                    max={2}
                    avatarData={participants}
                  />
                </Grid>
              </Typography>
              <Typography>
                <LocationOnIcon sx={{ mr: 1 }} />
                {address}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }
  );
}

export default EventCard;
