import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function FilterBugs({ filter, setFilter, setPage, getBugsData, setTableBody }) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Filter </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            padding: "5px",
          }}
        >
          <TextField
            id="standard-basic"
            label="Search"
            variant="standard"
            value={filter.search}
            style={{ width: "100%" }}
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
          />

          <div>
            <FormLabel id="demo-radio-buttons-group-label">Priority</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={filter.priority}
              name="radio-buttons-group"
              sx={{ display: "flex", flexDirection: "row " }}
              onChange={(e) =>
                setFilter({ ...filter, priority: e.target.value })
              }
            >
              <FormControlLabel value="LOW" control={<Radio />} label="Low" />
              <FormControlLabel
                value="MEDIUM"
                control={<Radio />}
                label="Medium"
              />
              <FormControlLabel value="HIGH" control={<Radio />} label="High" />
              <FormControlLabel value="ALL" control={<Radio />} label="All" />
            </RadioGroup>
          </div>

          <div>
            <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={filter.status}
              name="radio-buttons-group"
              onChange={(e) => setFilter({ ...filter, status: e.target.value })}
              sx={{ display: "flex", flexDirection: "row " }}
            >
              <FormControlLabel value="OPEN" control={<Radio />} label="Open" />
              <FormControlLabel
                value="CLOSED"
                control={<Radio />}
                label="Closed"
              />
            </RadioGroup>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              Is deleted
            </FormLabel>
            <FormControlLabel
              onChange={() =>
                setFilter({ ...filter, deletedAt: !filter.deletedAt })
              }
              control={<Checkbox checked={filter.deletedAt} />}
              label="True"
            />
          </div>
          <div style={{ marginTop: "auto", marginLeft: "auto" }}>
            <Button
              variant="outlined"
              size="middle"
              style={{ marginRight: "10px" }}
              onClick={() =>
                setFilter({
                  search: "",
                  deletedAt: false,
                  status: "OPEN",
                  priority: "ALL",
                })
              }
            >
              Clear
            </Button>
            <Button
              variant="outlined"
              size="middle"
              onClick={() => {
                setTableBody();
                setPage(0);
                getBugsData(0, filter);
              }}
            >
              SUBMİT
            </Button>
          </div>
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
}

export default FilterBugs;
