import { useState } from "react";

import { chatContext } from "../../../context/chatContext";
// import ChatComp from "./chatComp";
import ReactChat from "react-chat-comp";
import "react-chat-comp/chat.css";
import TestWallpaper from "../../../style/images/test.jpg";
function ViewChatList() {
  const [chatList, setChatList] = useState([]);
  const chatListValues = { chatList, setChatList };
  return (
    <chatContext.Provider value={chatListValues}>
      <ReactChat
        ownerId="123"
        conversationList={[]}
        conversationContents={[]}
        wallpaper={TestWallpaper}
      ></ReactChat>
    </chatContext.Provider>
  );
}

export default ViewChatList;
