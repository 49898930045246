import React, { useState } from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Star } from "@mui/icons-material";
import { createPhenomenon } from "../../../api/phenomenon";

const validationSchema = yup.object({
  id: yup.string("Enter your id").required("Id is required"),
  phone: yup.string("Enter your phone").required("Phone is Required"),
});

function Phenomenon() {
  const [errorMessage, setErrorMessage] = useState("");

  var formik = useFormik({
    initialValues: {
      id: "",
      phone: "+90",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const response = await createPhenomenon({
        _id: values.id,
        phone: values.phone,
      });

      setErrorMessage(response.message);
    },
  });

  return (
    <Card>
      <CardHeader
        title={
          <span
            style={{
              display: "flex",
              gridColumnGap: "10px",
              alignItems: "center",
            }}
          >
            <Star /> Create Phenomenon
          </span>
        }
      ></CardHeader>
      <CardContent>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            style={{ marginBottom: "15px" }}
            id="id"
            name="id"
            label="ID"
            variant="standard"
            value={formik.values.id}
            onChange={formik.handleChange}
            error={formik.touched.id && Boolean(formik.errors.id)}
            helperText={formik.touched.id && formik.errors.id}
          />
          <TextField
            id="phone"
            name="phone"
            label="Phone"
            type="Phone"
            variant="standard"
            value={formik.values.phone}
            onChange={(e) =>
              e.target.value.length <= 2 ? "" : formik.handleChange(e)
            }
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
          <div className={errorMessage ? "errorMessage" : ""}>
            <p>{errorMessage && errorMessage}</p>
          </div>

          <Button
            disabled={
              formik.values.phone.length !== 13 ||
              formik.values.id.length !== 24
            }
            className="login-form_btn"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2, width: "100px", ml: "auto" }}
          >
            Create
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

export default Phenomenon;
