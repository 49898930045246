import { useState } from "react";

import { RequestListContext } from "../../../context/userRequestListContext";
import RequestList from "./requestList";

function ViewRequestList() {
  const [requestList, setRequestList] = useState([]);
  const requestListValues = { requestList, setRequestList };
  return (
    <RequestListContext.Provider value={requestListValues}>
      <RequestList />
    </RequestListContext.Provider>
  );
}

export default ViewRequestList;
