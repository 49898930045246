/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

/*-------------------API Functions------------------ */
import { RequestListContext } from "../../../context/userRequestListContext";
import { getRequestList } from "../../../api/user";
import { urlUserId } from "../../../helper/takeIdFromUrl";

/*-------------------Component/Helper------------------ */
import LoadingSpinner from "../../../components/custom/loadingSpinner";
import FriendList from "../../../components/custom/friendsListComponent";
import EmptyCard from "../../../components/custom/emptyCardComponent";

function RequestList() {
  const { requestList, setRequestList } = useContext(RequestListContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    const request = await getRequestList(urlUserId());
    setRequestList(request.data);
    setIsLoaded(true);
  };

  return isLoaded ? (
    requestList.length > 0 ? (
      <FriendList
        requestList={requestList}
        custom={{ borderRadius: 3, mt: 1, cursor: "pointer" }}
      />
    ) : (
      <EmptyCard
        custom={{
          borderRadius: 5,
          height: "38rem",
        }}
        description={"The user has not any requests"}
      />
    )
  ) : (
    <LoadingSpinner />
  );
}

export default RequestList;
