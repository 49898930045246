export const getFullDateAndHour = (date) => {
  return date.substr(0, 16).replace("T", " ");
};

export const dateFormat = (time) => {
  const parseData = new Date(time);
  return `${parseData.getFullYear()}-${`0${parseData.getMonth() + 1}`.slice(
    -2
  )}-${`0${parseData.getDate()}`.slice(-2)}`;
};
