import AvatarComponent from "./avatarComponent";
import {
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

import { useNavigate } from "react-router";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function FriendList({ requestList, custom }) {
  const history = useNavigate();
  return requestList.map(({ partner }) => {
    return (
      <Card
        key={partner._id}
        onClick={() => {
          history(`/profile/${partner._id}`);
          window.location.reload();
        }}
        sx={custom}
      >
        <List>
          <ListItem>
            <ListItemAvatar>
              <AvatarComponent
                status={"Avaible"}
                custom={{ width: 54, height: 54 }}
                avatarImage={partner.profileImage.surl}
              />
            </ListItemAvatar>

            <ListItemText sx={{ mt: 1, ml: 2 }} primary={partner.username} />
            {/* <CheckCircleOutlineIcon color="success" sx={{ mt: 1 }} />
                <HighlightOffIcon color="error" sx={{ mt: 1 }} /> */}
          </ListItem>
        </List>
      </Card>
    );
  });
}

export default FriendList;
