import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  Pagination,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  createPhenomenon,
  getFollowers,
  getPhenomenons,
} from "../../../api/phenomenon";
import AvatarComponent from "../../../components/custom/avatarComponent";
import ToolTipComponent from "../../../components/custom/toolTipComponent";
import { BugReport } from "@mui/icons-material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  NextProfileList,
  ProfileList,
} from "../../../context/phenomenonContext";
import FollowersList from "./followersList";
import TagIcon from "@mui/icons-material/Tag";
import WarningIcon from "@mui/icons-material/Warning";
import SnackBarsComponent from "../../../components/custom/snackBarsComponent";
import ModalComponent from "../../../components/custom/modalComponent";
import ButtonComponent from "../../../components/custom/buttonComponent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { createTransaction } from "../../../api/transaction";

function PhenomenonList() {
  const phenomenonList = ProfileList();
  const setPhenomenonList = NextProfileList();

  const [paymentOption, setPaymentOption] = useState("false");

  const history = useNavigate();

  // Regenerate code
  const [phenomenonInformation, setPhenomenonInformation] = useState({
    phone: "",
    id: "",
    action: "",
    money: 0,
  });

  // System message
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);

  // Pagination
  const [page, setPage] = useState(0);
  const [limit] = useState(20);
  const [totalCount, setTotalCount] = useState(0);

  //Accordion clicked current phenomenonId
  const [currentPhenomenonId, setCurrentPhenomenonId] = useState(null);

  // followers State
  const [followers, setFollowersData] = useState({
    followers: [],
    page: 0,
    limit: 9999,
    totalCount: 0,
    moneyEarnedFromFollowers: [],
  });

  const cleanPhenomenonState = () => {
    setPhenomenonInformation({
      phone: "",
      id: "",
      action: "",
      money: 0,
    });
  };

  const handleChange = async (event, value) => {
    var _id = undefined;

    if (phenomenonList[page].length > 0) {
      _id = phenomenonList[page][phenomenonList[page].length - 1]._id._id;
    }
    await getPhenomenonsList(value - 1, _id);
  };

  const handleOnConfirm = async () => {
    const { id, phone, money, action } = phenomenonInformation;

    if (action === "CODE_GENERATE") {
      await createPhenomenon({
        _id: id,
        phone: phone,
      });
    } else {
      const sendValue = { phenomenonId: id, moneyToGiven: Number(money) };
      if (paymentOption === "false") {
        delete sendValue["moneyToGiven"];
      }
      await createTransaction(sendValue);
    }

    await getPhenomenonsList(page);
    setOpenMessage(true);
    setMessage(
      action === "CODE_GENERATE"
        ? "Code regenerated !"
        : "Transaction Created !"
    );
    cleanPhenomenonState();
  };

  const PaymentComponent = () => {
    return (
      <FormControl>
        <FormLabel style={{ color: "white" }}>Choose Method</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => setPaymentOption(e.target.value)}
          value={paymentOption}
        >
          <FormControlLabel
            value="true"
            control={<Radio />}
            label="Enter The Value"
          />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="System Decide"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  // Closed Pagination

  // const handleFollowerPage = async (event, value) => {
  //   var id = undefined;

  //   if (followers.followers[followers.page].length > 0) {
  //     id =
  //       followers.followers[followers.page][
  //         followers.followers[followers.page].length - 1
  //       ]._id;
  //   }

  //   await getFollowersApi(currentPhenomenonId, value - 1, id);
  // };

  useEffect(() => {
    getPhenomenonsList(page);
  }, []);

  const handleChangeInput = (event) => {
    const inputValue = event.target.value;

    if (inputValue === "" || inputValue >= 0) {
      setPhenomenonInformation({
        ...phenomenonInformation,
        money: inputValue,
      });
    }
  };

  const getPhenomenonsList = async (page, id) => {
    //Checking array data, is it include this arrays

    if (isPageInculude(page) && id) {
      setPage(page);
      return phenomenonList;
    }

    // Request Incoming data
    const profiles = await getPhenomenons(limit, id);
    let newUserList = JSON.parse(JSON.stringify(phenomenonList));
    newUserList[page] = profiles.data.phenomenons;

    setPhenomenonList(newUserList);
    setTotalCount(profiles.data.totalCount);
    setPage(page);
  };

  const getFollowersApi = async (phenomenonId, page, id) => {
    setCurrentPhenomenonId(phenomenonId);

    if (isFollowerPageInculude(page) && id) {
      setFollowersData({ ...followers, page: page });
      return followers.followers;
    }

    const result = await getFollowers(phenomenonId, followers.limit, id);
    let newUserList = JSON.parse(JSON.stringify(followers.followers));
    newUserList[page] = result.data.followers;

    setFollowersData({
      ...followers,
      page: page,
      followers: { ...followers.followers, ...newUserList },
      totalCount: result.data.totalCount,
      moneyEarnedFromFollowers: result.data.moneyEarnedFromFollowers,
    });
  };

  const accordionOpenFunc = (phenomenonId) => {
    if (phenomenonId === currentPhenomenonId) {
      return "ok";
    }

    setCurrentPhenomenonId(phenomenonId);
    setFollowersData({
      followers: [],
      page: 0,
      limit: 1,
      totalCount: 0,
      moneyEarnedFromFollowers: [],
    });

    getFollowersApi(phenomenonId, 0);
  };

  const isFollowerPageInculude = (pageValue) => {
    const calculateTotalPage = followers.totalCount / limit;

    return followers.followers[pageValue] || pageValue === calculateTotalPage;
  };

  const isPageInculude = (pageValue) => {
    const calculateTotalPage = totalCount / limit;

    return phenomenonList[pageValue] || pageValue === calculateTotalPage;
  };

  return phenomenonList[page] && phenomenonList[page].length > 0 ? (
    <div>
      <Card>
        {phenomenonList[page].map(
          ({
            phone,
            moneyEarnedFromBugs,
            moneyEarnedFromFollowers,
            _id,
            paidMoney,
          }) => {
            const totalMoney =
              moneyEarnedFromFollowers.length * 50 + moneyEarnedFromBugs;

            return (
              <Accordion
                style={{
                  padding: "0px",
                  overflow: "scroll",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                expanded={currentPhenomenonId === _id?._id}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      onClick={() => accordionOpenFunc(_id._id)}
                      style={{ cursor: "pointer" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    cursor: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => history(`/profile/${_id?._id}`)}
                  >
                    <AvatarComponent avatarImage={_id?.profileImage} disabled />
                    <span style={{ color: "green" }}>{_id?.username} </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setPhenomenonInformation({
                        id: _id._id,
                        phone: _id.phone,
                        action: "CODE_GENERATE",
                      })
                    }
                  >
                    <TagIcon /> {_id?.activationCode}
                  </div>

                  <div className="accordion-phenomenon-main">
                    <div className="accordion-phenomenon-child">
                      <ToolTipComponent
                        icon={
                          <AttachMoneyIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              paidMoney === totalMoney
                                ? ""
                                : setPhenomenonInformation({
                                    id: _id._id,
                                    phone: _id.phone,
                                    money: 0,
                                    action: "PAY_MONEY",
                                  })
                            }
                          />
                        }
                        placement="left"
                        title="Pay to money"
                      />
                    </div>
                    <div className="accordion-phenomenon-child">
                      <ToolTipComponent
                        icon={<BugReport />}
                        placement="left"
                        title="Gained from bugs"
                      />
                      {moneyEarnedFromBugs}
                    </div>
                    <div className="accordion-phenomenon-child">
                      <ToolTipComponent
                        icon={<PeopleAltIcon />}
                        placement="left"
                        title="Gained from followers"
                      ></ToolTipComponent>{" "}
                      {moneyEarnedFromFollowers.length * 50}
                    </div>

                    <div
                      className="accordion-phenomenon-child"
                      style={{
                        color: paidMoney === totalMoney ? "green" : "red",
                      }}
                    >
                      Paid:
                      {paidMoney}
                    </div>
                    <div
                      className="accordion-phenomenon-child"
                      style={{ color: "green" }}
                    >
                      Total:
                      {totalMoney}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <FollowersList
                    followersData={followers}
                    // handleFollowerPage={(event, value) =>
                    //   handleFollowerPage(event, value)
                    // }
                  />
                </AccordionDetails>
              </Accordion>
            );
          }
        )}
      </Card>
      <ModalComponent
        title={
          <span style={{ display: "flex", gap: "10px" }}>
            <WarningIcon /> Warning
          </span>
        }
        body={
          <ButtonComponent
            description={
              phenomenonInformation.action === "CODE_GENERATE" ? (
                "Do You want to Regenerate code ?"
              ) : (
                <div className="transaction">
                  <PaymentComponent />
                  <Input
                    onChange={(e) => handleChangeInput(e)}
                    value={phenomenonInformation.money}
                    placeholder="Enter the paid value"
                    style={{
                      opacity: paymentOption === "true" ? 1 : 0,
                      visibility: paymentOption === "true" ? "" : "hidden",
                      marginTop: paymentOption === "true" ? "20px" : "0px",
                      marginBottom: paymentOption === "true" ? "20px" : "0px",
                    }}
                  />
                </div>
              )
            }
            button1Name={"Yes"}
            button2Name={"No"}
            onConfirm={() => handleOnConfirm()}
            onCancel={() => cleanPhenomenonState()}
            customStyle={{ marginLeft: "auto", gap: "20px", display: "flex" }}
          ></ButtonComponent>
        }
        open={phenomenonInformation.action !== ""}
        setOpen={() => cleanPhenomenonState()}
      ></ModalComponent>

      <SnackBarsComponent
        open={openMessage}
        handleClose={() => setOpenMessage(false)}
        horizontal="right"
        vertical="top"
        message={message}
      />

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Pagination
          count={Math.ceil(totalCount / limit)}
          page={page + 1}
          onChange={handleChange}
          color="primary"
        />
      </div>
    </div>
  ) : (
    <Card
      style={{
        height: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "20px",
        padding: "20px",
      }}
    >
      You don't have phenomens
    </Card>
  );
}

export default PhenomenonList;
