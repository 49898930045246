import React from "react";
import List from "@mui/material/List";
import { SiderBarItems } from "./sideBarItems";

export function ListMenu(props) {
  const { open } = props;

  return (
    <List sx={{ padding: 0 }} component="nav">
      <SiderBarItems open={open} />
    </List>
  );
}
