/* eslint-disable no-loop-func */
/* eslint-disable no-duplicate-case */
import RedirectLogin from "../helper/redirectLogin";
import { Buffer } from "buffer";
const apiUrl = process.env.REACT_APP_BASE_URL;

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${
    localStorage.getItem("adminToken") &&
    localStorage.getItem("adminToken").split('"')[1]
  }`,
};

const getBugs = async (limit, filter, id) => {
  var requestOptions = {
    method: "GET",
    headers,
  };
  try {
    let url = `${apiUrl}/admin/bugs?limit=${limit}`;
    if (id) {
      url = `${url}&&id=${id}`;
    }

    if (filter) {
      if (filter.deletedAt) {
        url = `${url}&&deletedAt=true`;
      }
      if (filter.priority !== "ALL") {
        url = `${url}&&priority=${filter.priority}`;
      }

      if (filter.search !== "") {
        url = `${url}&&search=${filter.search}`;
      }
      if (filter.status) {
        url = `${url}&&status=${filter.status}`;
      }
    }

    const response = await fetch(url, requestOptions);

    const data = await response.json();

    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

const privateFuncForBugs = async (formData, bugsData, limit, filter) => {
  formData.append("title", bugsData.title);
  formData.append("description", bugsData.description);
  formData.append("priority", bugsData.priority);
  formData.append("phenomenon", bugsData.phenomenon);
  if (bugsData.id) {
    formData.append("id", bugsData.id);
  }
  var requestOptions = {
    headers: {
      Authorization: `Bearer ${
        localStorage.getItem("adminToken") &&
        localStorage.getItem("adminToken").split('"')[1]
      }`,
    },
    method: "POST",
    body: formData,
  };
  try {
    const url = `${apiUrl}/admin/bugs`;
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    await getBugs(limit, filter);
    return data;
  } catch (error) {
    console.log(error);
  }
};

const createBugs = async (bugsData, limit, filter) => {
  const formData = new FormData();

  let filesCounter = bugsData.bugImages.length || 0;
  const readFileArr = [];
  const readFilePromises = [];

  if (filesCounter === 0) {
    return await privateFuncForBugs(formData, bugsData, limit, filter);
  }

  //Read file
  for (let i = 0; i < bugsData.bugImages.length; i++) {
    const { file } = bugsData.bugImages[i];
    const fileReader = new FileReader();
    const readFilePromise = new Promise((resolve) => {
      fileReader.onloadend = () => {
        const arrayBuffer = fileReader.result;

        readFileArr.push({ data: arrayBuffer, file: file });
        resolve();
      };
    });
    fileReader.readAsArrayBuffer(file);
    readFilePromises.push(readFilePromise);
  }
  // take all reading file
  await Promise.all(readFilePromises);

  for (let b = 0; b < readFileArr.length; b++) {
    const buffer = Buffer.from(readFileArr[b].data);
    const blob = new Blob([buffer], { type: readFileArr[b].file.type });

    formData.append(`images`, blob, readFileArr[b].file.name);
  }
  return await privateFuncForBugs(formData, bugsData, limit, filter);
};

const updateBugs = async (bugsData) => {
  var requestOptions = {
    method: "PUT",
    body: JSON.stringify(bugsData),
    headers,
  };
  try {
    const url = `${apiUrl}/admin/bugs`;
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

const deleteBugs = async (bugsData) => {
  var requestOptions = {
    method: "DELETE",
    body: JSON.stringify(bugsData),
    headers,
  };
  try {
    const url = `${apiUrl}/admin/bugs`;
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export { getBugs, createBugs, updateBugs, deleteBugs };
