import { Card, CardContent } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { getAllTransactions } from "../../api/transaction";
import SearchComponent from "../../components/custom/searchComponent";
import TableComponent from "../../components/custom/tableComponent";
import { TransactionListContext } from "../../context/transactionListContext";

function TransactionList() {
  const { transactions, setTransactions } = useContext(TransactionListContext);
  const [searchUser, setSearchUser] = useState("");

  const [refLoaded, setRefLoaded] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const prevTableBody = useRef();

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = async () => {
    const response = await getAllTransactions();
    setTransactions(response.data);
  };

  const tableHeaderNames = [
    "TransactionID",
    "Phenomenon",
    "Admin",
    "Paid",
    "TotalMoney",
    "CreatedAt",
  ];

  useEffect(() => {
    const tableBody = transactions
      ? transactions.map(
          ({ _id, phenomenon, admin, paidMoney, totalMoney, createdAt }) => {
            return {
              _id,
              phenomenon,
              admin,
              paidMoney,
              totalMoney,
              createdAt,
            };
          }
        )
      : [];

    if (refLoaded === false && tableBody.length > 0) {
      prevTableBody.current = tableBody;
      setRefLoaded(true);
    }
    setTransactions(tableBody);
  }, [transactions?.length]);

  /* Search User Client Side */
  useEffect(() => {
    if (searchUser !== "") {
      searchFunction();
    } else {
      setTransactions(prevTableBody.current);
    }
  }, [searchUser]);

  const searchFunction = () => {
    if (prevTableBody.current.length > 0) {
      const filtered = transactions.filter(
        (data) =>
          (data?.phenomenon?.username &&
            data?.phenomenon?.username
              .toLowerCase()
              .includes(searchUser.toLowerCase())) ||
          (data?.phenomenon?.surname &&
            data?.phenomenon?.surname
              .toLowerCase()
              .includes(searchUser.toLowerCase())) ||
          (data?.phenomenon?.name &&
            data?.phenomenon?.name
              .toLowerCase()
              .includes(searchUser.toLowerCase())) ||
          (data?.phenomenon?.phone &&
            data?.phenomenon?.phone
              .toLowerCase()
              .includes(searchUser.toLowerCase())) ||
          (data?.admin?.name &&
            data?.admin?.name.toLowerCase().includes(searchUser.toLowerCase()))
      );

      setTransactions(filtered);
    }
  };

  return transactions ? (
    <Card>
      <div>
        {" "}
        <SearchComponent
          setSearchUser={(value) => setSearchUser(value)}
          setTableLoading={(value) => setTableLoading(value)}
          setTableBody={(value) => setTransactions(value)}
          searchUser={searchUser}
          refLoaded={refLoaded}
          prevTableBody={prevTableBody}
          searchFor="TRANSACTION"
        />
      </div>
      <CardContent sx={{ textAlign: "center", overflow: "scroll" }}>
        <TableComponent
          disablePagination={true}
          tableHeaderNames={tableHeaderNames}
          tableBody={transactions ?? []}
          hiddenId={false}
          tableLoading={tableLoading}
        />
      </CardContent>
    </Card>
  ) : (
    ""
  );
}

export default TransactionList;
