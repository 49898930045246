import UserProfile from "./userProfile";
import { ProfileContext } from "../../../context/userProfileContex";
import { useState } from "react";

function ViewProfile() {
  const [profile, setProfile] = useState(null);
  const value = { profile, setProfile };
  return (
    <ProfileContext.Provider value={value}>
      <UserProfile />
    </ProfileContext.Provider>
  );
}

export default ViewProfile;
