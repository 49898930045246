import { createContext, useContext, useState } from "react";

const UserListContext = createContext();
const UpdateUserListContext = createContext();

export const ProfileList = () => {
  return useContext(UserListContext);
};

export const NextProfileList = () => {
  return useContext(UpdateUserListContext);
};

export function UserListProvider({ value, children }) {
  const [userList, setUserList] = useState(value);

  return (
    <UserListContext.Provider value={userList}>
      <UpdateUserListContext.Provider value={setUserList}>
        {children}
      </UpdateUserListContext.Provider>
    </UserListContext.Provider>
  );
}