import { Card } from "@mui/material";

function EmptyCard({ description, custom = {}}) {
  Object.assign(custom, {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  });

  return <Card sx={custom}>{description}</Card>;
}

export default EmptyCard;
