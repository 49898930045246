import getUnicodeFlagIcon from "country-flag-icons/unicode";

function CountryComponent({ country }) {
  switch (typeof country) {
    case "object":
      if (country.length > 0) {
        return country.map((data, i) => {
          if (i >= 5) {
            return "";
          }

          return getUnicodeFlagIcon(data.countryCode || data.lang);
        });
      }
      return "-";
    case "string":
      return getUnicodeFlagIcon(country);
    default:
      break;
  }
}

export default CountryComponent;
