/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  CardMedia,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";

/*--------------------ICONS---------------------------- */
import TranslateIcon from "@mui/icons-material/Translate";
import PublicIcon from "@mui/icons-material/Public";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import MdPhone from "@mui/icons-material/Phone";
import TtyIcon from "@mui/icons-material/Tty";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LockIcon from "@mui/icons-material/Lock";

import background from "../../../style/images/test2.jpg";

/*-----------------Components/Helpers------------------- */
import { urlUserId } from "../../../helper/takeIdFromUrl";
import LoadingSpinner from "../../../components/custom/loadingSpinner";
import ImagesList from "../../../components/custom/imageListComponent";
import AvatarComponent from "../../../components/custom/avatarComponent";
import CountryComponent from "../../../components/custom/countryComponent";
import EventCard from "../../../components/custom/eventCardComponent";
import EmptyCard from "../../../components/custom/emptyCardComponent";
import ViewRequestList from "../requestList";
import ViewFriendList from "../friendList";
import ToolTipComponent from "../../../components/custom/toolTipComponent";
// import ChatComponent from "../../../components/custom/chatComponent";
import ViewChatList from "../chatList";

/*------------------API Functions---------------------- */
import { getEventsByOwner } from "../../../api/event";
import { getUserProfile } from "../../../api/user";
import { ProfileContext } from "../../../context/userProfileContex";

const inputKeyValues = [
  // { keys: "bio", description: "", icon: "" },
  { keys: "_id", description: "UserID", icon: <Grid3x3Icon />, copy: true },
  {
    keys: "phone",
    description: "Phone Number",
    icon: <MdPhone />,
    copy: false,
  },
  {
    keys: "phoneLang",
    description: "Phone Language",
    icon: <TtyIcon />,
    copy: false,
  },
  {
    keys: "languages",
    description: "Languages",
    icon: <TranslateIcon />,
    copy: false,
  },
  {
    keys: "nationalities",
    description: "Nationalities",
    icon: <PublicIcon />,
    copy: false,
  },
  {
    keys: "isPrivate",
    description: "Is Private Account",
    icon: <LockIcon />,
    copy: false,
  },
  {
    keys: "isDeleted",
    description: "Is Deleted Account",
    icon: <RemoveCircleOutlineIcon />,
    copy: false,
  },
];

const TabValues = [
  "Profile",
  "Gallery",
  "Friends",
  "Request List",
];

function UserProfile() {
  const { profile, setProfile } = useContext(ProfileContext);
  const [tableBody, setTableBody] = useState([]);
  const [value, setValue] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    getProfileFunc();
  }, []);

  const getProfileFunc = async () => {
    const profileData = await getUserProfile(urlUserId());
    const events = await getEventsByOwner(urlUserId());
    setProfile(profileData.data);
    setTableBody(events.data);
    setIsLoaded(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index } = props;

    return <div>{value === index && <div>{children}</div>}</div>;
  }

  function TabsComponent() {
    return (
      <div className="user-tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons={true}
          variant="scrollable"
        >
          {TabValues.map((tab) => (
            <Tab key={tab} label={tab} />
          ))}
        </Tabs>
      </div>
    );
  }

  function ProfileCard() {
    return (
      <Card sx={{ mb: 2, borderRadius: "0px 0px 15px 15px" }}>
        <Grid container>
          <CardMedia height={200} component="img" image={background} alt="H" />

          <CardHeader
            sx={{ p: 0, mt: -8 }}
            title={
              <Grid container>
                <AvatarComponent
                  avatarClassName="user-avatar"
                  custom={{ width: 120, height: 120 }}
                  avatarImage={profile["profileImages"][0].murl}
                  disabled
                />
                <span className="user-avatar-name">{profile["username"]}</span>
              </Grid>
            }
          />

          <Grid xs="12">
            <TabsComponent />
          </Grid>
        </Grid>
      </Card>
    );
  }

  function StatsCard({ friendsCount, eventCount, joinedEventCount }) {
    return (
      <Grid sx={{ mb: 2, textAlign: "center" }} item xs={12} sm={12}>
        <Card sx={{ borderRadius: 5 }}>
          <CardContent>
            <Grid container>
              <Grid item xs={3.3} sm={4} md={3}>
                <CardHeader title={friendsCount} subheader={"Friends"} />
              </Grid>
              <hr />
              <Grid item xs={3.3} sm={4} md={3}>
                <CardHeader title={eventCount} subheader={"Event Count"} />
              </Grid>
              <hr />
              <Grid item xs={3.3} sm={4} md={4}>
                <CardHeader
                  title={joinedEventCount}
                  subheader={"Joined Event Count"}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function UserInformationCard() {
    return (
      <Card sx={{ borderRadius: 5 }}>
        <CardHeader
          sx={{ p: 0, ml: 1 }}
          disableTypography
          title={<h3 style={{ marginLeft: "1.5rem" }}>About</h3>}
          subheader={
            <Typography sx={{ ml: 3 }}>{profile["bio"] || "-"}</Typography>
          }
        />
        <CardContent sx={{ ml: 1 }}>
          {inputKeyValues.map(({ keys, icon, description, copy }) => {
            return (
              <Typography key={keys} sx={{ ml: 1 }}>
                <Grid container columnSpacing={1} rowGap={0}>
                  <Grid item>
                    <ToolTipComponent
                      icon={icon}
                      title={description}
                      placement="left"
                    />
                  </Grid>
                  <Grid item>
                    {keys === "phoneLang" ||
                    keys === "nationalities" ||
                    keys === "languages" ? (
                      <CountryComponent country={profile[keys]} />
                    ) : copy ? (
                      <span
                        style={{ color: "#1876c5", cursor: "pointer" }}
                        onMouseLeave={() => setClicked(false)}
                        onClick={() => {
                          navigator.clipboard.writeText(profile[keys]);
                          setClicked(true);
                        }}
                      >
                        <ToolTipComponent
                          placement={"top"}
                          title={clicked ? "Copied !" : "Click To Copy Board"}
                          icon={`${profile[keys]} `}
                        ></ToolTipComponent>
                      </span>
                    ) : (
                      `${profile[keys]}`
                    )}
                  </Grid>
                </Grid>
              </Typography>
            );
          })}
        </CardContent>
      </Card>
    );
  }

  function UserEventCard() {
    return tableBody.length > 0 ? (
      <Card
        sx={{ maxHeight: "38rem", overflow: "scroll", borderRadius: "20px" }}
      >
        <Grid container rowSpacing={3} columnSpacing={20}>
          <EventCard
            sm={12}
            tableBody={tableBody}
            userInfo={{
              profileImage: profile["profileImages"][0].murl,
              username: profile["username"],
            }}
          />
        </Grid>
      </Card>
    ) : (
      <EmptyCard
        custom={{
          borderRadius: 5,
          height: "100%",
          minHeight: "400px",
          padding: "20px",
          width: "100%",
        }}
        description={" The user has not created an event."}
      />
    );
  }

  return isLoaded ? (
    <>
      <ProfileCard />
      <TabPanel value={value} index={0}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12} sm={5} md={5}>
            <StatsCard
              friendsCount={profile.friendsCount}
              eventCount={profile.createdEventsCount}
              joinedEventCount={profile.participatedEventsCount}
            />
            <UserInformationCard />
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <UserEventCard />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ImagesList img={profile.profileImages} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ViewFriendList />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ViewRequestList />
      </TabPanel>
    </>
  ) : (
    <LoadingSpinner />
  );
}

export default UserProfile;
