import { Pagination, Stack } from "@mui/material";

function PaginationComponent({
  tableBody,
  totalCount,
  page,
  setPage,
  getEvents,
}) {
  const handleChangePage = async (event, newPage) => {
    let _id = undefined;
    if (tableBody.length > 0) {
      _id = tableBody[tableBody.length - 1]._id;
    }
    setPage(newPage);
    return await getEvents(newPage, _id);
  };
  return (
    <Stack
      style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
      spacing={2}
    >
      <Pagination onChange={handleChangePage} page={page} count={totalCount} />
    </Stack>
  );
}

export default PaginationComponent;
