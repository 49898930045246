import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import LogoutIcon from '@mui/icons-material/Logout';
import AvatarComponent from "../../custom/avatarComponent";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import sosiroLogo from "../../../style/images/sosiro-logo.png";
import { Menu } from "@material-ui/core";
import {useState} from "react";
import { MenuItem } from "@mui/material";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export function HeaderDasboard(props) {
  const { open, setOpenProps } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <AppBar sx={{ backgroundColor: "black" }} position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpenProps()}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, mt: 1  }}
        >
          <img  style={{display: !open || "none"}} src={sosiroLogo} height="40rem" id="logoLight" alt="I" />
        </Typography>

        {/* Dashboard head icons  */}
        <IconButton sx={{ mr: 2 }} color="inherit">
          <Badge badgeContent={0} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>

        <IconButton color="inherit" onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}>
          <AvatarComponent avatarImage={""} disabled />
        </IconButton>

        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} style={{marginTop:"47px"}} onClose={() => {setAnchorEl(null);}} >
          <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
           <AccountCircleIcon style={{marginRight:"10px"}}/>
                Profile
          </MenuItem>
          <MenuItem
              onClick={async () => {
                setAnchorEl(null);
                localStorage.clear('adminToken');
                window.location.href = '/login'
              }}
            >
              <LogoutIcon style={{marginRight:"10px"}}/>
              Sign out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

