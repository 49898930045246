import { PhenomenonListProvider } from "../../../context/phenomenonContext";
import PhenomenonList from "./phenomenonList";

function ViewPhenomenonList() {
  return (
    <PhenomenonListProvider value={[]}>
      <PhenomenonList/>
    </PhenomenonListProvider>
  );
}

export default ViewPhenomenonList;
