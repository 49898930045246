/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Skeleton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { decideReview, getReviewsList } from "../../api/review";
import ConfirmPopover from "../../components/custom/confirmPopover";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import {
  NextReviewsList,
  ReviewList,
  ReviewListProvider,
} from "../../context/reviewContext";
import { Star } from "@mui/icons-material";
import PaginationComponent from "../../components/custom/paginationComponent";

import UpdateIcon from "@mui/icons-material/Update";
import HistoryIcon from "@mui/icons-material/History";
import { getFullDateAndHour } from "../../helper/dateConvert";

const statusColors = {
  PENDING: "warning",
  APPROVED: "success",
  REJECTED: "error",
};

const tabValues = {
  1: "APPROVED",
  2: "REJECTED",
};

function Reviews() {
  const reviewList = ReviewList();
  const setReviewList = NextReviewsList();
  const [loading, setLoading] = useState(false);

  // For pagination
  const [page, setPage] = useState(1);

  const [limit] = useState(12);
  const [totalCount, setTotalCount] = useState(40);

  // separated actions (APPROVED, REJECTED, PENDING)
  const [tab, setTab] = useState(0);

  // When the admin decide, user information coming from here
  const [review, setReview] = useState({
    action: "",
    reviewId: "",
    isEdited: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState("");
  const [flip, setFlip] = useState("");

  const handleClick = (event, id, action, isEdited) => {
    setAnchorEl(event.currentTarget);
    setReview({
      action,
      reviewId: id,
      isEdited,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    setAnchorEl(null);
    setIsLoading(review.reviewId);
    setFlip("");
    await decideReview(review.reviewId, review.action, review.isEdited);
    await getReviews(page, tabValues[tab]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setLoading(true);
    getReviews(page, tabValues[tab]);
  }, [tab]);

  const getReviews = async (page, filter, id) => {
    //Checking its last array
    if (isPageInculude(page) && id) {
      return reviewList;
    }
    // Request Incoming data
    const reviewsData = await getReviewsList(limit, filter, id);

    let newUserList = JSON.parse(JSON.stringify(reviewList));
    newUserList[page] = reviewsData.data;

    setReviewList(newUserList);
    setLoading(false);
    setTotalCount(reviewsData.totalCount);
  };

  const isPageInculude = (pageValue) => {
    const calculateTotalPage = totalCount / limit;
    return reviewList[pageValue] || pageValue === calculateTotalPage;
  };

  const BackCard = (data) => {
    return (
      <>
        <div className="review-badge">
          <Badge
            style={{
              display: "flex",
              flexDirection: "row",
              right: "20px",
            }}
            badgeContent={data.isApproved}
            color={statusColors[data.isApproved]}
          />

          {/* <Badge
            className="custom-badge"
            style={{
              display: data.createdAt !== data.updatedAt ? "flex" : "none",
              backgroundColor: "red",
            }}
            badgeContent={<span> EDITED </span>}
            color={statusColors[data.isApproved]}
          /> */}
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="review-image-container">
            <div className="review-image">
              {data?.editedImages?.length > 0 ? (
                data?.editedImages.map(({ murl }) => {
                  return (
                    <img
                      src={murl}
                      style={{
                        borderRadius: "5%",
                      }}
                      alt={" "}
                    />
                  );
                })
              ) : data.images.length > 0 ? (
                data.images.map(({ murl }) => {
                  return (
                    <img
                      src={murl}
                      style={{
                        borderRadius: "5%",
                      }}
                      alt={" "}
                    />
                  );
                })
              ) : (
                <div className="review-no-image">
                  <p>No Image</p>
                </div>
              )}
            </div>
            <div className="review-image-description">
              <div style={{ color: "white", fontWeight: "500" }}>
                {getFullDateAndHour(data.createdAt) || ""}
              </div>
              <div style={{ display: "flex" }}>
                {data?.editedServiceRate
                  ? data?.editedServiceRate
                  : data?.serviceRate}{" "}
                <Star />
              </div>
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            {data?.editedComment === undefined || data?.editedComment === null
              ? data.comment
              : data?.editedComment}
          </div>
        </div>
      </>
    );
  };

  const FrontCard = (data) => {
    return (
      <>
        <div className="review-badge">
          <Badge
            style={{
              display: flip === data._id ? "none" : "flex",
              flexDirection: "row",
              right: "20px",
            }}
            badgeContent={data.isApproved}
            color={statusColors[data.isApproved]}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="review-image-container">
            <div className="review-image">
              {data.images.length > 0 ? (
                data.images.map(({ murl }) => {
                  return (
                    <img
                      src={murl}
                      style={{
                        borderRadius: "5%",
                      }}
                      alt={" "}
                    />
                  );
                })
              ) : (
                <div className="review-no-image">
                  <p>No Image</p>
                </div>
              )}
            </div>

            <div className="review-image-description">
              <div style={{ color: "white", fontWeight: "500" }}>
                {getFullDateAndHour(data.createdAt) || ""}
              </div>
              <div style={{ display: "flex" }}>
                <Star /> {data.serviceRate || ""}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>{data.comment}</div>
        </div>
      </>
    );
  };

  const SkeltonShimmerCard = () => {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card
          style={{
            minHeight: "400px",
            maxHeight: "400px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          {/* Image */}
          <Skeleton variant="rounded" width={"100%"} height={190} />

          {/* Description */}
          <Skeleton
            variant="rounded"
            width={"100%"}
            height={60}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />

          {/* Buttons */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              marginLeft: "auto",
              marginTop: "auto",
            }}
          >
            <Skeleton
              variant="rounded"
              width={70}
              height={30}
              style={{ borderRadius: "4px" }}
            />
            <Skeleton
              variant="rounded"
              width={70}
              height={30}
              style={{ borderRadius: "4px" }}
            />
          </div>
        </Card>
      </Grid>
    );
  };

  return (
    <ReviewListProvider value={[]}>
      <BottomNavigation
        showLabels
        style={{ marginBottom: "20px" }}
        value={tab}
        onChange={(event, newValue) => {
          setTab(newValue);
          setPage(1);
          setReviewList([]);
        }}
      >
        <BottomNavigationAction label="Pending" icon={<PendingActionsIcon />} />
        <BottomNavigationAction label="Accepted" icon={<DoneIcon />} />
        <BottomNavigationAction label="Rejected" icon={<CloseIcon />} />
      </BottomNavigation>

      <Grid sx={{ p: 0 }} container rowSpacing={4} columnSpacing={4}>
        {loading ? (
          <>
            <SkeltonShimmerCard />
            <SkeltonShimmerCard />
            <SkeltonShimmerCard />
          </>
        ) : reviewList[page] && reviewList[page].length > 0 ? (
          reviewList[page].map((data) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  className={flip === data._id ? "flip-card" : ""}
                  style={{
                    minHeight: "400px",
                    maxHeight: "400px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent className="content">
                    {(flip === "" ? 1 : 0) ? BackCard(data) : FrontCard(data)}
                  </CardContent>

                  {isLoading === data._id ? (
                    ""
                  ) : tab === 0 ? (
                    <CardActions
                      className="content"
                      disableSpacing
                      sx={{
                        mt: "auto",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "auto",
                          marginLeft: "5px",
                          cursor: "pointer",
                          display:
                            data.updatedAt !== data.createdAt ? "" : "none",
                        }}
                        onClick={() => setFlip(flip === "" ? data._id : "")}
                      >
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          style={{
                            color: "white",
                            fontSize: "10px",
                            backgroundColor: "orange",
                          }}
                        >
                          {flip === data._id ? (
                            <HistoryIcon sx={{ marginRight: "4px" }} />
                          ) : (
                            <UpdateIcon sx={{ marginRight: "4px" }} />
                          )}

                          {flip === data._id
                            ? "Günceli Göster "
                            : "Düzenleme Geçmişi"}
                        </Button>
                      </div>

                      <Button
                        sx={{
                          marginRight: "10px",
                          display: flip === data._id ? "none" : "",
                        }}
                        aria-describedby={id}
                        id={data._id}
                        onClick={(e) =>
                          handleClick(
                            e,
                            data._id,
                            "APPROVED",
                            data.createdAt !== data.updatedAt
                          )
                        }
                        variant="contained"
                        size="small"
                      >
                        Accept
                      </Button>
                      <Button
                        sx={{
                          display: flip === data._id ? "none" : "",
                        }}
                        aria-describedby={id}
                        onClick={(e) =>
                          handleClick(
                            e,
                            data._id,
                            "REJECTED",
                            data.createdAt !== data.updatedAt
                          )
                        }
                        variant="contained"
                        color="error"
                        size="small"
                      >
                        Reject
                      </Button>
                    </CardActions>
                  ) : (
                    ""
                  )}

                  <ConfirmPopover
                    description={"Are you sure to approve this review?"}
                    confirmText="Yes"
                    cancelText="No"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={() => handleClose()}
                    handleConfirm={() => handleConfirm()}
                  />
                </Card>
              </Grid>
            );
          })
        ) : (
          <Card
            style={{
              borderRadius: "5px",
              marginTop: "31px",
              marginLeft: "31px",
              height: "500px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No Data
          </Card>
        )}
      </Grid>

      {reviewList[page] && reviewList[page].length > 0 ? (
        <PaginationComponent
          page={page}
          setPage={(page) => setPage(page)}
          totalCount={Math.ceil(totalCount / limit)}
          tableBody={reviewList[page]}
          getEvents={(newPage, _id) => getReviews(newPage, tabValues[tab], _id)}
        />
      ) : (
        ""
      )}
    </ReviewListProvider>
  );
}

export default Reviews;
