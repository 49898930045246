import RedirectLogin from "../helper/redirectLogin";

const apiUrl = process.env.REACT_APP_BASE_URL;

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${
    localStorage.getItem("adminToken") &&
    localStorage.getItem("adminToken").split('"')[1]
  }`,
};

const getAllTransactions = async () => {
  var requestOptions = {
    method: "GET",
    headers,
  };
  try {
    const url = `${apiUrl}/admin/transaction`;

    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

const createTransaction = async (billing) => {
  var requestOptions = {
    method: "POST",
    body: JSON.stringify(billing),
    headers,
  };
  try {
    const url = `${apiUrl}/admin/transaction`;
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export { getAllTransactions, createTransaction };
