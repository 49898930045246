import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Outlet } from "react-router-dom";

import { ListMenu } from "./menu/sideBar";
import { CopyRight } from "../../components/layouts/footer/footer";
import { HeaderDasboard } from "../../components/layouts/header/header";
import logo from "../../style/images/sosiro-logo.png";
const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

export function Layout() {
  const [open, setOpen] = React.useState(true);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  // Auto close or open sidebar menu
  React.useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    setOpen(windowWidth < 500 ? false : true);
    // Trigger this function on resize
    window.addEventListener("resize", reportWindowSize);
    //  Cleanup for componentWillUnmount
    return () => window.removeEventListener("resize", reportWindowSize);
  }, [windowWidth]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        {/* Header component */}
        <HeaderDasboard open={open} setOpenProps={() => setOpen(!open)} />

        {/* Left Sidebar Menu  */}
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <img src={logo} height="50rem" id="logo" alt="" />
            <IconButton onClick={() => setOpen(!open)}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />

          {/* List Sidebar Menu  */}
          <ListMenu open={open} />
          {/* Footer CopyRigh */}
          <CopyRight
            open={open}
            style={{ padding: "5px", fontSize: "13px", marginTop: "auto" }}
          />
        </Drawer>

        {/* Dashboard inside for tables or others */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          {/* Outlet, mathcing the child route */}
          <div id="mainContent">
            <Outlet />
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Layout;
