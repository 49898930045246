import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import { Avatar } from "@mui/material";

const statusColor = {
  Avaible: "#44b700",
  Busy: "yellow ",
  Off: "red ",
};

function AvatarComponent({ avatarClassName, status, avatarImage, custom, disabled }) {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      display: disabled ? "none" : "",
      backgroundColor: statusColor[status],
      color: statusColor[status],
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  return (
    <Stack direction="row" >
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
      >
        <Avatar className={avatarClassName} sx={custom} src={avatarImage} />
      </StyledBadge>
    </Stack>
  );
}

export default AvatarComponent;
