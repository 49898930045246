/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Card, CardHeader, Grid, Typography } from "@mui/material";
import { getStats } from "../../api/chart";
import { ChartsContext } from "../../context/chartsContext";
import LoadingSpinner from "../../components/custom/loadingSpinner";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GaugeChart from "react-gauge-chart";
import ReactTooltip from "react-tooltip";
import { FcInfo } from "react-icons/fc";

ChartJS.register(ArcElement, Tooltip, Legend);

// const chartsTypes = [
//   { keys: "userCount", title: "User Total Count " },
//   { keys: "eventCount", title: "Total Event Count" },
//   { keys: "deviceCount", title: "Total Device Count" },
// ];

function Charts() {
  const { charts, setCharts } = useContext(ChartsContext);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    getStatsValues();
  }, []);

  const getStatsValues = async () => {
    const stats = await getStats();

    setCharts(stats.data);
    setIsLoaded(true);
  };

  /*Charts data options*/
  const data = {
    labels: [`Total Device Count`, `Total Event Count`, `Total User Count`],
    datasets: [
      {
        label: "My First Dataset",
        data: [charts?.deviceCount, charts?.eventCount, charts?.userCount],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        hoverOffset: 10,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            datalabels: {
              // This code is used to display data values
              anchor: "end",
              align: "top",
              formatter: Math.round,
              font: {
                weight: "bold",
                size: 16,
              },
            },
          },
        },
      },
    ],
  };

  const userAccountSecurity = charts?.userCount / charts?.deviceCount;

  return isLoaded ? (
    <>
      {/* HOME HEAD */}
      <Grid container>
        <Grid sm="12" marginBottom={3}>
          <Card sx={{ display: "flex", justifyContent: "space-between" }}>
            <CardHeader sx={{ height: "75px" }} title={<DashboardIcon />} />
            <CardHeader
              sx={{ height: "75px" }}
              title={<h6>Sosiro Dashboard</h6>}
            />
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* FOR CHARTS */}
        <Grid item className="homeChart" sm="7">
          <Card>
            <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
              Application Reports
            </Typography>
            <Grid sx={{ width: "400px", margin: "0 auto", padding: "20px" }}>
              <Doughnut data={data} />
            </Grid>
          </Card>
        </Grid>
        {/* USER ACCOUNT SECURITY */}
        <Grid item sm="5">
          <Grid item>
            <Card>
              <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                User Account Security
              </Typography>
              <Card sx={{ display: "flex" }}>
                <GaugeChart
                  id="gauge-chart5"
                  nrOfLevels={420}
                  arcsLength={[0.3, 0.5, 0.2]}
                  colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                  percent={userAccountSecurity}
                  arcPadding={0.001}
                  arcWidth={0.4}
                  style={{ width: "60%" }}
                  hideText={true}
                />
                <h3>
                  % {userAccountSecurity * 100}{" "}
                  <FcInfo data-tip="User Count / Device Count" />
                  <ReactTooltip />
                </h3>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid container rowSpacing={4} columnSpacing={3} marginBottom={3}>
        {chartsTypes.map(({ keys, title }) => {
          return (
            <Grid item sm="4">
              <Card sx={{ borderRadius: 3 }}>
                <CardHeader sx={{ height: '75px' }} title={<h6>{title}</h6>} />
                <Typography>{charts[keys]} </Typography>
              </Card>
            </Grid>
          );
        })}
      </Grid> */}
    </>
  ) : (
    <LoadingSpinner />
  );
}

export default Charts;
