import { useState } from "react";
import { ChartsContext } from "../../context/chartsContext";
import Charts from "./charts";

function ViewCharts() {
  const [charts, setCharts] = useState([]);
  const value = { charts, setCharts };
  return (
    <ChartsContext.Provider value={value}>
      <Charts />
    </ChartsContext.Provider>
  );
}

export default ViewCharts;
