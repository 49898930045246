/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";

/*-------------------Component/Helper------------------ */
import { urlUserId } from "../../../helper/takeIdFromUrl";
import FriendsCard from "../../../components/custom/friendsCardComponent";
import EmptyCard from "../../../components/custom/emptyCardComponent";
import LoadingSpinner from "../../../components/custom/loadingSpinner";

/*-------------------API Functions------------------ */
import { getFriendList } from "../../../api/user";
import { FriendListContext } from "../../../context/userFriendListContext";

function FriendList() {
  const { friendList, setFriendList } = useContext(FriendListContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getFriends();
  }, []);

  const getFriends = async () => {
    const friends = await getFriendList(urlUserId());
    setFriendList(friends.data);
    setIsLoaded(true);
  };

  return isLoaded ? (
    friendList.length > 0 ? (
      <Grid container rowSpacing={2} columnSpacing={1} md={12}>
        <FriendsCard
          friendList={friendList}
          sm={4}
          xs={6}
          custom={{ borderRadius: 5, }}
        />
      </Grid>
    ) : (
      <EmptyCard
        custom={{
          borderRadius: 5,
          height: "38rem",
        }}
        description={" The user has not friend."}
      />
    )
  ) : (
    <LoadingSpinner />
  );
}

export default FriendList;
