import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as yup from "yup";

//----------- Images ---------
import loginImg from "../../style/images/login.png";
import sosiroLogo from "../../style/images/sosiro-logo.png";

//----------- API ------------
import { getToken } from "../../api/user";
import { TextField } from "@mui/material";
import SnackBarsComponent from "./snackBarsComponent";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.sigmatelecom.com/">
        Sigma Telecom
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(4, "Password should be of minimum 4 characters length")
    .required("Password is required"),
});

function LoginForm() {
  useEffect(() => {
    localStorage.clear();
    if (window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  }, []);

  const [openMessage, setOpenMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const value = JSON.stringify(values, null, 2);
      const response = await getToken(value);
      setOpenMessage(true);
      setErrorMessage(response.message);
    },
  });

  return (
    <div className="loginAnimation">
      <Container
        component="main"
        className="loginBackGround"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Grid item sm={6}>
            <img
              src={loginImg}
              className="login-img"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
            <img
              id="login-logo"
              style={{ marginBottom: "30px" }}
              src={sosiroLogo}
              alt=""
            />
            <form onSubmit={formik.handleSubmit}>
              <TextField
                style={{ marginBottom: "15px" }}
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />

              {/* <FormControlLabel
                id="form-checkbox"
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                className="login-form_btn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </form>
            {/* <Grid container>
              <Grid item xs>
                <Link className="login-form_link" href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>

        <SnackBarsComponent
          open={openMessage}
          handleClose={() => setOpenMessage(false)}
          horizontal="right"
          vertical="top"
          message={errorMessage}
        />

        <Copyright />
      </Container>
    </div>
  );
}

export default LoginForm;
