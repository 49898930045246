/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from "@react-google-maps/api";
import { useNavigate } from "react-router";
import { getUserLocations } from "../../api/user";

function Maps() {
  const [activeMarker, setActiveMarker] = useState(null);
  const [locations, setLocations] = useState(null);

  const mapRef = useRef(null);

  useEffect(() => {
    (async () => {
      const data = await getUserLocations();
      const b = data.data.filter((a) => {
        return a.location?.coordinates !== undefined;
      });
      setLocations(b);
    })();
  }, []);

  const history = useNavigate();

  const options = {
    imagePath:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Circle_%28transparent%29.png/800px-Circle_%28transparent%29.png", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
    imageSizes: [40, 50, 60, 70],
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD4KIOsvCXwm99tnPHxTK9f010E9t57YwM",
  });

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const [mapPosition, setMapPosition] = useState({
    lat: 38.516407,
    lng: 43.603435,
  });

  const handleOnLoad = (map) => {
    mapRef.current = map;
  };

  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setMapPosition(newPos);
  }

  var shape = {
    coords: [50, 50, 60],
    type: "circle",
  };

  return (
    <div className="map">
      {isLoaded ? (
        <GoogleMap
          onLoad={handleOnLoad}
          mapContainerStyle={{ minHeight: "86vh", borderRadius: "20px", border:"1px solid orange" }}
          onClick={() => setActiveMarker(null)}
          onDragEnd={handleCenter}
          center={mapPosition}
          zoom={4}
        >
          <MarkerClusterer clusterClass="cluster" options={options}>
            {(clusterer) =>
              locations &&
              locations.map((info) => (
                <Marker
                  icon={{
                    url: info.profileImage,
                    scale: 1,
                    optimized: false,
                    scaledSize: {
                      width: 50,
                      height: 50,
                    },
                    size: {
                      height: 50,
                      width: 50,
                    },
                  }}
                  key={info._id}
                  shape={shape}
                  position={{
                    lat: info.location.coordinates[1],
                    lng: info.location.coordinates[0],
                  }}
                  clusterer={clusterer}
                  onClick={() => handleActiveMarker(info._id)}
                >
                  {activeMarker === info._id ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <div onClick={() => history(`/profile/${info._id}`)}>
                        {info.username}
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Maps;
