import { AvatarGroup } from "@mui/material";
import AvatarComponent from "./avatarComponent";

function GroupAvatarComponent({ avatarData, custom, max }) {
  return (
    <AvatarGroup
      variant="circular"
      sx={custom}
      max={max}
      total={avatarData.length}
    >
      {avatarData.map((data) => {
        return (
          <AvatarComponent
            key={data._id}
            avatarImage={data.profileImage}
            custom={{ width: 24, height: 24 }}
            disabled
          />
        );
      })}
    </AvatarGroup>
  );
}

export default GroupAvatarComponent;
