import { Button } from "@mui/material";

function ButtonComponent({
  description,
  button1Name,
  button2Name,
  onConfirm,
  onCancel,
  customStyle,
  disabled,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
      }}
    >
      {description}
      <div style={customStyle}>
        <Button
          disabled={disabled}
          variant="contained"
          color="primary"
          style={{ color: "white" }}
          onClick={() => onConfirm()}
        >
          {button1Name}
        </Button>
        <Button
          variant="contained"
          color="error"
          style={{ color: "white" }}
          onClick={() => onCancel()}
        >
          {button2Name}
        </Button>
      </div>
    </div>
  );
}

export default ButtonComponent;
