import { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

function ModalComponent({ open, setOpen, style, title, body }) {
  const [cardCollapsed, setCardCollapsed] = useState("row");
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : ""
  );

  useEffect(() => {
    function userPostImageResize() {
      setWindowWidth(window.innerWidth);
    }
    setCardCollapsed(windowWidth < 750 ? "column" : "row");
    // Trigger this function on resize
    window.addEventListener("resize", userPostImageResize);
    //  Cleanup for componentWillUnmount
    return () => window.removeEventListener("resize", userPostImageResize);
  }, [windowWidth]);

  /* ESCAPE ON MODAL  WITH ESC */
  const escModal = useCallback((event) => {
    if (event.key === "Escape") {
      setOpen(false);
    }
  }, []);

  // Escape modal hook
  useEffect(() => {
    document.addEventListener("keydown", escModal, false);
    return () => {
      document.removeEventListener("keydown", escModal, false);
    };
  }, [open]);

  const ModalHeader = () => {
    return (
      <>
        <div
          className="custom-modal-card custom-modal-card-flex"
          style={{
            borderRadius: "10px 10px 0px 0px",
            width: cardCollapsed === "row" ? "570px" : "",
          }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {title}
      
            <CloseIcon
              onClick={() => setOpen(false)}
            //   className="custom-modal-close"
              style={{
                color: "white",
                fontSize: "30px",
                marginRight: "16px",
                cursor:"pointer"
              }}
            />
          </div>
          <hr style={{ width: "100%", padding: "0px", margin: "0px" }} />
        </div>
      </>
    );
  };

  const ModalBody = () => {
    return (
      <div
        className="custom-modal-card custom-modal-card-flex"
        style={{
          borderRadius: "0px 0px 10px 10px",
          width: cardCollapsed === "row" ? "570px" : "",
          height: "100%",
          maxHeight: "352px",
          overflow: "scroll",
          padding: "0px 12px 0px 1px",
        }}
      >
        {body}
      </div>
    );
  };

  return (
    <div style={{ display: open ? "" : "none" }} className="custom-modal">
      <div
        style={{
          maxHeight: "448px",
          minWidth: "285px",
          overflow: "hidden",
          padding: "20px",
        }}
        className={`flex-${cardCollapsed}`}
      >
        {cardCollapsed === "column" ? (
          <div>
            <div>{ModalHeader()} </div>
            <div className="custom-modal-card-flex">{ModalBody()}</div>
          </div>
        ) : (
          <>
            <div className="custom-modal-card-flex">
              {ModalHeader()}
              {ModalBody()}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ModalComponent;
