import { useState } from "react";

import { FriendListContext } from "../../../context/userFriendListContext";
import FriendList from "./friendList";

function ViewFriendList() {
  const [friendList, setFriendList] = useState([]);
  const value = { friendList, setFriendList };
  return (
    <FriendListContext.Provider value={value}>
      <FriendList />
    </FriendListContext.Provider>
  );
}

export default ViewFriendList;
