import RedirectLogin from "../helper/redirectLogin";

const apiUrl = process.env.REACT_APP_BASE_URL

const headers = {
  "Content-Type": "application/json",
  "Authorization": `Bearer ${localStorage.getItem("adminToken") && localStorage.getItem("adminToken").split('"')[1]}`
};

const getEventList = async (limit, id) => {
  var requestOptions = {
    method: "GET",
    headers,
  };
  try {
    let url = `${apiUrl}/admin/events?limit=${limit}`;
    if (id) {
      url = `${url}&&id=${id}`;
    }
    const response = await fetch(url, requestOptions);
    const data =  await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data 
  } catch (error) {
    console.log(error);
  }
};


const getEventsByOwner = async ( id) => {
  var requestOptions = {
    method: "GET",
    headers,
  };
  try {
    let url = `${apiUrl}/admin/profiles/${id}/events?limit=${999}&skip=${0}`;
    const response = await fetch(url, requestOptions);

    const data =  await response.json();
    if (data.statusCode === 401) {
      RedirectLogin();
    }
    return data 
  } catch (error) {
    console.log(error);
  }
};



export { getEventList, getEventsByOwner };
