import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/layouts/Layout";
import ViewEvents from "./views/event";
import ViewUserList from "./views/user/userList/index";
import ViewProfile from "./views/user/userProfile/index";
import ViewCharts from "./views/charts";
import ViewMap from "./views/map";
import LoginForm from "./components/custom/loginFormComponent";
import ViewReviewList from "./views/review";
import ViewReportList from "./views/report";
import ViewCreatePhenomenon from "./views/phenomenon/createPhenomenon";
import ViewBugsList from "./views/bugs";
import ViewPhenomenonList from "./views/phenomenon/phenomenonList";
import ViewTransactions from "./views/transaction";
// import history from "./history";

function AppRouter() {
  return (
    <Router>
      <Routes>
        {localStorage.getItem("adminToken") ? (
          <Route element={<Layout />}>
            <Route exact path="/charts" element={<ViewCharts />} />
            <Route exact path="/userList" element={<ViewUserList />} />
            <Route exact path="/profile/:id" element={<ViewProfile />} />
            <Route exact path="/events" element={<ViewEvents />} />
            <Route exact path="/map" element={<ViewMap />} />
            <Route exact path="/reports" element={<ViewReportList />} />
            <Route exact path="/reviewList" element={<ViewReviewList />} />
            <Route
              exact
              path="/createPhenomen"
              element={<ViewCreatePhenomenon />}
            />
            <Route exact path="/phenomenons" element={<ViewPhenomenonList />} />
            <Route exact path="/bugs" element={<ViewBugsList />} />
            <Route exact path="/transaction" element={<ViewTransactions />} />
          </Route>
        ) : (
          <Route path="/login" element={<LoginForm />} />
        )}
        <Route path="*" href element={<LoginForm />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
