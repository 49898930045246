import { createContext, useContext, useState } from "react";

const PhenomenonListContext = createContext();
const UpdatePhenomenonListContext = createContext();

export const ProfileList = () => {
  return useContext(PhenomenonListContext);
};

export const NextProfileList = () => {
  return useContext(UpdatePhenomenonListContext);
};

export function PhenomenonListProvider({ value, children }) {
  const [phenomenonList, setPhenomenonList] = useState(value);

  return (
    <PhenomenonListContext.Provider value={phenomenonList}>
      <UpdatePhenomenonListContext.Provider value={setPhenomenonList}>
        {children}
      </UpdatePhenomenonListContext.Provider>
    </PhenomenonListContext.Provider>
  );
}