/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

/*------------------API Functions---------------------- */
import { ProfileList, NextProfileList } from "../../../context/userListContext";
import { getAllUser } from "../../../api/user";

/*-----------------Components/Helpers------------------- */
import TableComponent from "../../../components/custom/tableComponent";
import LoadingSpinner from "../../../components/custom/loadingSpinner";
import SearchComponent from "../../../components/custom/searchComponent";

function UserList() {
  const userList = ProfileList();
  const setUserList = NextProfileList();
  const prevTableBody = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [tableBody, setTableBody] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(100);
  const [page, setPage] = useState(0);

  const [searchUser, setSearchUser] = useState("");

  const [refLoaded, setRefLoaded] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getUsers(page);
  }, []);

  useEffect(() => {
    const tableBody = userList[page]
      ? userList[page].map(
          ({
            _id,
            username,
            profileImage,
            name,
            surname,
            phone,
            gender,
            isDeleted,
            isPrivate,
          }) => {
            return {
              _id,
              username,
              profileImage,
              name,
              surname,
              phone,
              gender,
              isDeleted,
              isPrivate,
            };
          }
        )
      : [];

    setTableBody(tableBody);
    //Previous states stored
    if (refLoaded === false && tableBody.length > 0) {
      prevTableBody.current = tableBody;
      setRefLoaded(true);
    }
  }, [page, userList.length]);

  const getUsers = async (page, id) => {
    setRefLoaded(false);
    setIsLoading(true);
    //Checking array data, is it include this arrays
    if (isPageInculude(page) && id) {
       setIsLoading(false);
      return userList;
    }

    // Request Incoming data
    const profiles = await getAllUser(limit, id);
    let newUserList = JSON.parse(JSON.stringify(userList));
    newUserList[page] = profiles.data;

    setUserList(newUserList);
    setIsLoading(false);
    setTotalCount(profiles.totalCount);
  };

  const isPageInculude = (pageValue) => {
    const calculateTotalPage = totalCount / limit;
    return userList[pageValue] || pageValue === calculateTotalPage;
  };

  const tableHeaderNames = [
    "UserID",
    "Username",
    "Profile Images",
    "Name & Surname",
    "Phone Number",
    "Gender",
    "Account Deleted",
    "Private Account",
  ];

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <div>
        <SearchComponent
          setSearchUser={(value) => setSearchUser(value)}
          setTableLoading={(value) => setTableLoading(value)}
          setTableBody={(value) => setTableBody(value)}
          searchUser={searchUser}
          refLoaded={refLoaded}
          prevTableBody={prevTableBody}
          searchFor="USER_LIST"
        />
      </div>
      <CardContent sx={{ textAlign: "center", overflow: "scroll" }}>
        <TableComponent
          tableHeaderNames={tableHeaderNames}
          tableBody={tableBody}
          totalCount={totalCount}
          prevTableBody={prevTableBody.current}
          tableLoading={tableLoading}
          limit={limit}
          page={page}
          setPage={(page) => setPage(page)}
          getUsers={(newPage, id) => getUsers(newPage, id)}
          hiddenId={false}
        />
      </CardContent>
    </Card>
  );
}

export default UserList;
