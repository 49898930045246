/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";

import { navigationConfigFirst } from "../../../config/navigationConfig";
import ToolTipComponent from "../../custom/toolTipComponent";
import { History } from "../../../history";

export const SiderBarItems = ({ open }) => {
  const [expanded, setExpanded] = useState("");

  const [activeBar, setActiveBar] = useState("");
  useEffect(() => {
    setActiveBar(window.location.pathname);
  }, [window.location.pathname]);

  return navigationConfigFirst.map(({ icon, title, id, child, navLink }) => {
    return child ? (
      <Fragment key={id}>
        <Accordion
          expanded={id === expanded}
          TransitionProps={{ unmountOnExit: true }}
          elevation={0}
          onClick={() => setExpanded(id === expanded ? "" : id)}
        >
          <AccordionSummary
            sx={{
              marginLeft: open || "45px",
              marginRight: "15px",
              padding: "0px",
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <span style={{ marginLeft: open ? "8px" : "-21px", color: "grey" }}>
              <ToolTipComponent
                iconStyle={{ color: "black" }}
                title={open ? "" : title}
                icon={icon}
                placement="right"
              />
            </span>
            <span style={{ display: open || "none", marginLeft: "32px" }}>
              {title}
            </span>
          </AccordionSummary>

          {/* </NavLink> */}
          {child.map((data) => {
            return (
              <NavLink
                key={data.id}
                style={{ color: "black", textDecoration: "none" }}
                to={data.navLink}
              >
                <AccordionDetails sx={{ padding: open ? "" : "0px" }}>
                  <ListItemButton
                    style={{
                      transition: "all 0.5s ease-out 0s",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: "0px",
                      color: activeBar === data.navLink ? "white" : "black",
                      background: activeBar === data.navLink ? "black" : "",
                    }}
                    onClick={() =>
                      setActiveBar(data.navLink, () => History(data.navLink))
                    }
                  >
                    <ListItemIcon>
                      <ToolTipComponent
                        iconStyle={{
                          color: activeBar === data.navLink ? "white" : "black",
                        }}
                        title={open ? "" : data.title}
                        icon={data.icon}
                        placement="right"
                      />
                    </ListItemIcon>
                    <ListItemText primary={data.title} />
                  </ListItemButton>
                </AccordionDetails>
              </NavLink>
            );
          })}
        </Accordion>
      </Fragment>
    ) : (
      <Fragment key={id}>
        <NavLink
          style={{ color: "black", textDecoration: "none" }}
          to={navLink}
        >
          <ListItemButton
            style={{
              transition: "all 0.5s ease-out 0s",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderRadius: "0px",
              color: activeBar === navLink ? "white" : "black",
              background: activeBar === navLink ? "black" : "",
            }}
            onClick={() => setActiveBar(navLink, () => History(navLink))}
          >
            <ListItemIcon>
              <ToolTipComponent
                title={open ? "" : title}
                icon={icon}
                placement="right"
                iconStyle={{ color: activeBar === navLink ? "white" : "black" }}
              />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItemButton>
        </NavLink>
      </Fragment>
    );
  });
};
