import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Card } from "@mui/material";
import { getFullDateAndHour } from "../../helper/dateConvert";

export default function ImagesList({ img }) {
  return (
    <Card sx={{ mb: 4 }} className="user-image-card">
      <ImageList   cols={5}>
        {img.map(({ lurl, _id, createdAt }) => {
          return (
            <ImageListItem key={_id}>
              <img
                src={lurl}
                style={{ borderRadius: "5%", height: "20rem", width: "21rem" }}
                alt={"I"}
              />
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 100%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 50%)",
                  opacity: "90%",
                  borderRadius: "0px 0px 13px 13px",
                  width: "21rem",
                }}
                title={getFullDateAndHour(createdAt)}
                position="bottom"
                actionIcon={
                  <IconButton sx={{ color: "white" }}>
                    <HighlightOffIcon />
                  </IconButton>
                }
                actionPosition="right"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </Card>
  );
}
