import { UserListProvider } from "../../../context/userListContext";
import UserList from "./userList";

function ViewUserList() {
  return (
    <UserListProvider value={[]}>
      <UserList />
    </UserListProvider>
  );
}

export default ViewUserList;
