import { useState } from "react";
import { EventListContext } from "../../context/eventListContext";
import EventList from "./eventList";


function ViewEvents() {
  const [eventList, setEventList] = useState([]);
  const value = { eventList, setEventList };
  return (
    <EventListContext.Provider value={value}>
      <EventList />
    </EventListContext.Provider>
  );
}

export default ViewEvents;
