import {
  Autocomplete,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import SelectMaterial from "@mui/material/Select";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";

function CreateOrUpdateBugs({
  openDrawer,
  setOpenDrawer,
  bugData,
  setBugData,
  action,
  onConfirm,
  phenomenonsOptions,
  files,
  setFiles,
  showImage,
  setShowImage,
}) {
  const priorityList = [
    { label: "LOW", color: "#00ff00" },
    { label: "MEDIUM", color: "#ffa500" },
    { label: "HIGH", color: "#ff0000" },
  ];

  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
    setBugData({
      ...bugData,
      [action]: { ...bugData[action], bugImages: incommingFiles },
    });
  };

  const onDelete = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const handleSee = (imageSource) => {
    setShowImage(imageSource);
  };

  const findPhenomen = (phenomenId) => {
    if (phenomenId) {
      return phenomenonsOptions.find(({ value }) => value === phenomenId);
    }
    return { value: "", label: "" };
  };

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={() => setOpenDrawer(false)}
      style={{ width: "100%", }}
    >
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          marginTop: "50px",
          overflowY:"scroll"
        }}
      >
        <TextField
          key={"title"}
          id="title"
          label="Title"
          variant="standard"
          value={bugData[action].title}
          style={{ width: "100%" }}
          onChange={(e) =>
            setBugData({
              ...bugData,
              [action]: { ...bugData[action], title: e.target.value },
            })
          }
        />
        <TextField
          key={"description"}
          id="description"
          label="Description"
          variant="standard"
          value={bugData[action].description}
          style={{ width: "100%", marginTop: "20px" }}
          onChange={(e) =>
            setBugData({
              ...bugData,
              [action]: { ...bugData[action], description: e.target.value },
            })
          }
        />

        <Autocomplete
          key={"auto"}
          style={{ marginTop: "20px" }}
          disablePortal
          id="combo-box-demo"
          options={phenomenonsOptions}
          onChange={(event, value) =>
            setBugData({
              ...bugData,
              [action]: { ...bugData[action], phenomenon: value.value },
            })
          }
          isOptionEqualToValue={(phenomenonsOptions, value) =>
            phenomenonsOptions.id === value.id
          }
          value={findPhenomen(bugData[action].phenomenon)}
          renderInput={(params) => <TextField {...params} label="Phenomen" />}
        />

        <div
          style={{
            marginTop: "10px",
            display: action === "UPDATE" ? "none" : "",
          }}
        >
          <Dropzone
            key={"dropzone"}
            onChange={updateFiles}
            value={files}
            maxHeight="220px"
            minHeight="220px"
            // disableScroll
            method="POST"
            footer=""
            accept="image/*"
            view="list"
            style={{
              padding: "10px",
              overflow: "hidden !important",
              maxWidth: "200px",
              minWidth: "200px",
            }}
          >
            {files.map((file) => (
              <FileItem
                {...file}
                key={file.id}
                resultOnTooltip
                preview
                // info
                hd
                alwaysActive={false}
                onDelete={onDelete}
                onSee={handleSee}
              />
            ))}
          </Dropzone>
          <FullScreenPreview
            imgSource={showImage}
            openImage={showImage}
            onClose={() => handleSee(undefined)}
          />
        </div>
        <FormControl style={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-label">Priority</InputLabel>
          <SelectMaterial
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={bugData[action].priority}
            label="Priority"
            onChange={(e) =>
              setBugData({
                ...bugData,
                [action]: { ...bugData[action], priority: e.target.value },
              })
            }
          >
            {priorityList.map(({ label, color }) => {
              return (
                <MenuItem
                  value={label}
                  style={{ display: "flex", flexDirection: "row", gap: "20px" }}
                >
                  <span
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      content: "",
                      background: color,
                    }}
                  />
                  <span style={{ color: "black" }}> {label} </span>
                </MenuItem>
              );
            })}
          </SelectMaterial>
        </FormControl>

        <Button
          variant="outlined"
          size="small"
          disabled={!Object.values(bugData[action]).every((o) => o !== "")}
          onClick={() => onConfirm(action)}
          style={{ marginTop: "20px" }}
        >
          {action}
        </Button>
      </div>
    </Drawer>
  );
}

export default CreateOrUpdateBugs;
