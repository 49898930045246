import { useEffect } from "react";

const TransactionQuery = (data, searchUser, searchFor) => {
  if (searchFor === "TRANSACTION") {
    return (
      (data?.phenomenon?.username &&
        data?.phenomenon?.username
          .toLowerCase()
          .includes(searchUser.toLowerCase())) ||
      (data?.phenomenon?.surname &&
        data?.phenomenon?.surname
          .toLowerCase()
          .includes(searchUser.toLowerCase())) ||
      (data?.phenomenon?.name &&
        data?.phenomenon?.name
          .toLowerCase()
          .includes(searchUser.toLowerCase())) ||
      (data?.phenomenon?.phone &&
        data?.phenomenon?.phone
          .toLowerCase()
          .includes(searchUser.toLowerCase())) ||
      (data?.admin?.name &&
        data?.admin?.name.toLowerCase().includes(searchUser.toLowerCase()))
    );
  }
  if (searchFor === "USER_LIST") {
    return (
      (data?.name &&
        data?.name.toLowerCase().includes(searchUser.toLowerCase())) ||
      (data?.surname &&
        data?.surname.toLowerCase().includes(searchUser.toLowerCase())) ||
      (data?.username &&
        data?.username.toLowerCase().includes(searchUser.toLowerCase())) ||
      (data?.phone &&
        data?.phone.toLowerCase().includes(searchUser.toLowerCase()))
    );
  }
};
function SearchComponent({
  setSearchUser,
  setTableLoading,
  setTableBody,
  searchUser,
  prevTableBody,
  refLoaded,
  searchFor,
}) {
  /* Search User Client Side */

  useEffect(() => {
    if (searchUser !== "") {
      searchFunction();
    }
    if (refLoaded && searchUser === "") {
      setTableLoading(false);
      setTableBody(prevTableBody.current);
    }
  }, [searchUser]);

  const searchFunction = () => {
    const filtered = prevTableBody.current.filter((data) =>
      TransactionQuery(data, searchUser, searchFor)
    );

    setTableBody(filtered);
    setTableLoading(false);
  };

  return (
    <div className="search-container">
      <input
        onChange={(e) => {
          setSearchUser(e.target.value);
          setTableLoading(true);
        }}
        className="search"
        placeholder="Search Content..."
      />
    </div>
  );
}

export default SearchComponent;
