import { Snackbar } from "@mui/material";

function SnackBarsComponent({
  open,
  handleClose,
  message,
  vertical,
  horizontal,
}) {
  return (
    <Snackbar
      key={vertical + horizontal}
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      open={open}
      autoHideDuration={6000}
      onClose={() => handleClose()}
      message={message}
    >
      {/* <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        This is a success message!
      </Alert> */}
    </Snackbar>
  );
}

export default SnackBarsComponent;
