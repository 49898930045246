import React, { useState } from "react";
import { ReportsListContext } from "../../context/reportListContext.js";
import ReportList from "./reportList";

function ViewReportList() {
  const [reports, setReports] = useState([]);
  const value = { reports, setReports };
  return (
    <ReportsListContext.Provider value={value}>
      <ReportList />
    </ReportsListContext.Provider>
  );
}

export default ViewReportList;
