import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export function CopyRight({ open, style }) {
  return (
    <Typography className={open ? "footer" : "footer-collapsed"} style={style}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.sigmatelecom.com/">
        Sigma Telecom
      </Link>
      <span style={{ marginLeft: "5px" }}>{new Date().getFullYear()} </span>
    </Typography>
  );
}
