import GroupAvatarComponent from "../../../components/custom/groupAvatarComponent";
import { useNavigate } from "react-router";
// import { Pagination } from "@mui/material";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const status = {
  SUCCESS: <TaskAltIcon color="success" />,
  FAIL: <HighlightOffIcon color="error" />,
};

const FollowersList = ({ followersData, handleFollowerPage }) => {
  const { followers, totalCount, moneyEarnedFromFollowers, page, limit } =
    followersData;
  const rules = (data) => {
    var output = Object.keys(data).map(function (key) {
      return { type: key, value: data[key] };
    });

    return (
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          // flexDirection: "row",
          gap: "20px",
        }}
      >
        {output.map(({ type, value }) => {
          if (
            type === "profileImages" ||
            type === "interests" ||
            type === "nationalities" ||
            type === "languages"
          ) {
            return value.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {type} {status.SUCCESS}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {type} {status.FAIL}
              </div>
            );
          }
          if (type === "enteredTime") {
            return value.length >= 2 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {type} {status.SUCCESS}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {type} {status.FAIL}
              </div>
            );
          }

          if (type === "bio" || type === "gender" || type === "birthDate") {
            return value !== undefined || value !== null ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {type} {status.SUCCESS}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {type} {status.FAIL}
              </div>
            );
          }
        })}
      </div>
    );
  };

  const history = useNavigate();
  return followers[page] && followers[page].length > 0 ? (
    <div style={{ padding: "20px", maxHeight: "500px", overflow: "scroll" }}>
      {followers[page].map(
        ({
          username,
          profileImages,
          phone,
          _id,
          bio,
          gender,
          birthDate,
          enteredTime,
          languages,
          interests,
          nationalities,
        }) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                width: "100%",
                marginBottom: "50px",
              }}
            >
              <GroupAvatarComponent
                avatarData={
                  profileImages.length > 0
                    ? profileImages.map((data) => {
                        return { profileImage: data.surl };
                      })
                    : []
                }
                custom={{
                  ".MuiAvatar-root": {
                    width: 44,
                    height: 44,
                    fontSize: 14,
                  },
                }}
                max={2}
              />
              <p
                style={{ color: "green", cursor: "pointer" }}
                onClick={() => history(`/profile/${_id}`)}
              >
                {username}
              </p>
              <div style={{ marginLeft: "auto" }}>
                {rules({
                  bio,
                  profileImages,
                  gender,
                  birthDate,
                  enteredTime,
                  languages,
                  interests,
                  nationalities,
                })}
              </div>
              {/* <div style={{ marginLeft: "10px" }}>
                {moneyEarnedFromFollowers.includes(phone) ? (
                  <span className="custom-badge-phenomenonon">WILLPAID</span>
                ) : (
                  ""
                )}
              </div> */}
            </div>
          );
        }
      )}

      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(totalCount / limit)}
          page={page + 1}
          onChange={(event, value) => handleFollowerPage(event, value)}
          color="primary"
        />
      </div> */}
    </div>
  ) : (
    ""
  );
};

export default FollowersList;
