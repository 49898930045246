/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import PaginationComponent from "../../components/custom/paginationComponent";
import LoadingSpinner from "../../components/custom/loadingSpinner";
import { EventListContext } from "../../context/eventListContext";
import { getEventList } from "../../api/event";
import EventCard from "../../components/custom/eventCardComponent";
import EmptyCard from "../../components/custom/emptyCardComponent";

function EventList() {
  const { eventList, setEventList } = useContext(EventListContext);
  const [tableBody, setTableBody] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(20);

  const [page, setPage] = useState(1);

  useEffect(() => {
    getEvents(page);
  }, []);

  useEffect(() => {
    const tableBody = eventList[page]
      ? eventList[page].map(
          ({
            _id,
            type,
            title,
            description,
            startDate,
            endDate,
            owner,
            nationalities,
            languages,
            participants,
            location,
            address,
            capacityCount,
            gender,
            upperAge,
            lowerAge,
            rating,
            reviewCount,
            currentCapacityCount,
            createdAt,
            updatedAt,
            conversation,
            reviewers,
            eventImage,
          }) => {
            return {
              _id,
              type,
              title,
              description,
              startDate,
              endDate,
              owner,
              nationalities,
              languages,
              participants,
              location,
              address,
              capacityCount,
              gender,
              upperAge,
              lowerAge,
              rating,
              reviewCount,
              currentCapacityCount,
              createdAt,
              updatedAt,
              conversation,
              reviewers,
              eventImage,
            };
          }
        )
      : [];

    setTableBody(tableBody);
  }, [page, eventList.length]);

  const getEvents = async (newPage, id) => {
    //Checking array data, is it include this arrays
    if (isPageInculude(newPage) && id) {
      return eventList;
    }

    // Request Incoming data
    const events = await getEventList(limit, id);
    let newEventList = JSON.parse(JSON.stringify(eventList));
    newEventList[newPage] = events.data;

    setEventList(newEventList);
    setTotalCount(events.totalCount);
    setIsLoaded(true);
  };

  const isPageInculude = (pageValue) => {
    const calculateTotalPage = totalCount / limit;
    return eventList[pageValue] || pageValue - 1 === calculateTotalPage;
  };

  return isLoaded ? (
    tableBody.length > 0 ? (
      <>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <EventCard xs={12} sm={6} md={4} lg={3} tableBody={tableBody} />
        </Grid>
        <PaginationComponent
          page={page}
          setPage={(newPage) => setPage(newPage)}
          totalCount={Math.ceil(totalCount / limit)}
          tableBody={tableBody}
          getEvents={(page, _id) => getEvents(page, _id)}
        />
      </>
    ) : (
      <EmptyCard
        description={"Event Not Found."}
        custom={{ height: "38rem" }}
      />
    )
  ) : (
    <LoadingSpinner />
  );
}

export default EventList;
