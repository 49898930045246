import { Box, Button, Popover } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
function ConfirmPopover({
  id,
  open,
  anchorEl,
  handleClose,
  handleConfirm,
  description,
  confirmText,
  cancelText,
}) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: 0,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          mt: "10px",
          "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            top: -6,
            transform: "rotate(45deg)",
            left: "21px",
          },
        }}
      />

      <div className="popover-container">
        <div style={{ display: "flex" }}>
          <ErrorIcon
            style={{ color: "orange", marginRight: "5px", width: "18px" }}
          />
          {description}
        </div>

        <div className="popover-button-container">
          <Button
            size="small"
            variant="contained"
            className="popover-button"
            style={{ marginRight: "10px" }}
            onClick={() => handleConfirm()}
          >
            {confirmText}
          </Button>
          <Button
            size="small"
            variant="outlined"
            className="popover-button"
            color="error"
            onClick={() => handleClose()}
          >
            {cancelText}
          </Button>
        </div>
      </div>
    </Popover>
  );
}

export default ConfirmPopover;
