/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getReportsList } from "../../api/report";
import TableComponent from "../../components/custom/tableComponent";
import { ReportsListContext } from "../../context/reportListContext";

function ReportList() {
  const { reports, setReports } = useContext(ReportsListContext);
  const [tableBody, setTableBody] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(20);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getReports(page);
  }, []);

  const tableHeaderNames = [
    "ReportID",
    "Reporter",
    "Reported",
    "Reason",
    "Review",
    "CreatedAt",
  ];

  useEffect(() => {
    const tableBody = reports[page]
      ? reports[page].map(
          ({ _id, reporter, reported, reasons, review, createdAt }) => {
            return {
              _id,
              reporter,
              reported,
              reasons,
              review,
              createdAt,
            };
          }
        )
      : [];

    setTableBody(tableBody);
  }, [page, reports.length]);

  const getReports = async (page, id) => {
    //Checking array data, is it include this arrays
    if (isPageInculude(page) && id) {
      return reports;
    }

    // Request Incoming data
    const reportsResp = await getReportsList(limit, id);
    let newUserList = JSON.parse(JSON.stringify(reports));

    newUserList[page] = reportsResp.data;

    setReports(newUserList);
    setTotalCount(reportsResp.totalCount);
  };

  const isPageInculude = (pageValue) => {
    const calculateTotalPage = totalCount / limit;
    return reports[pageValue] || pageValue === calculateTotalPage;
  };

  return (
    <Card>
      <CardContent sx={{ textAlign: "center", overflow: "scroll" }}>
        <TableComponent
          tableHeaderNames={tableHeaderNames}
          tableBody={tableBody}
          totalCount={totalCount}
          limit={limit}
          page={page}
          setPage={(page) => setPage(page)}
          getUsers={(newPage, id) => getReports(newPage, id)}
          hiddenId={false}
        />
      </CardContent>
    </Card>
  );
}

export default ReportList;
