import React from "react";
import { ReviewListProvider } from "../../context/reviewContext";
import Reviews from "./review";

function ViewReviewList() {
  return (
    <ReviewListProvider value={[]}>
      <Reviews />
    </ReviewListProvider>
  );
}

export default ViewReviewList;
