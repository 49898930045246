import CircularProgress from "@mui/material/CircularProgress";

function LoadingSpinner({ color, custom }) {
  return (
    <div style={{ textAlign: "center" }}>
      <CircularProgress color={color} sx={{ custom }} />
    </div>
  );
}

export default LoadingSpinner;
