import { Tooltip } from "@mui/material";

function ToolTipComponent({ title, id, icon, placement, iconStyle }) {
  return (
    <Tooltip title={title} placement={placement}>
    <span style={iconStyle}>{icon}</span>
  </Tooltip>)
}

export default ToolTipComponent;
