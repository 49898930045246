import AvatarComponent from "./avatarComponent";
import { useNavigate } from "react-router";
import { Card, CardActions, Grid, Typography } from "@mui/material";

function FriendsCard({ friendList, sm, xs, custom }) {
  const history = useNavigate();
  return friendList.map(({ partner, createdAt }) => {
    return (
      <Grid item key={partner._id} sm={sm} xs={xs}>
        <Card className="user-friend-list" sx={custom}>
          <CardActions>
            <AvatarComponent
              status={"Avaible"}
              custom={{ width: 54, height: 54 }}
              avatarImage={partner.profileImage.surl}
            />
          </CardActions>
          <Typography
            sx={{ cursor: "pointer", color: "green" }}
            onClick={() => {
              history(`/profile/${partner._id}`);
              window.location.reload();
            }}
          >
            {partner.username.length > 9
              ? partner.username.slice(0, 9) + "..."
              : partner.username}
          </Typography>
        </Card>
      </Grid>
    );
  });
}

export default FriendsCard;
