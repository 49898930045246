import { createContext, useContext, useState } from "react";

const BugsListContext = createContext();
const UpdateBugsListContext = createContext();

export const BugsList = () => {
  return useContext(BugsListContext);
};

export const NextBugsList = () => {
  return useContext(UpdateBugsListContext);
};

export function BugsListProvider({ value, children }) {
  const [bugsList, setBugsList] = useState(value);

  return (
    <BugsListContext.Provider value={bugsList}>
      <UpdateBugsListContext.Provider value={setBugsList}>
        {children}
      </UpdateBugsListContext.Provider>
    </BugsListContext.Provider>
  );
}