// import { UserListProvider } from "../../context/userListContext";
import { BugsListProvider } from "../../context/bugsListContext";
import BugsReportList from "./bugsList";

function ViewBugsList() {
  return (
    <BugsListProvider value={[]}>
      <BugsReportList />
    </BugsListProvider>
  );
}

export default ViewBugsList;
