import { createContext, useContext, useState } from "react";

const ReviewListContext = createContext();
const UpdateReviewListContext = createContext();

export const ReviewList = () => {
  return useContext(ReviewListContext);
};

export const NextReviewsList = () => {
  return useContext(UpdateReviewListContext);
};

export function ReviewListProvider({ value, children }) {
  const [reviewList, setReviewList] = useState(value);

  return (
    <ReviewListContext.Provider value={reviewList}>
      <UpdateReviewListContext.Provider value={setReviewList}>
        {children}
      </UpdateReviewListContext.Provider>
    </ReviewListContext.Provider>
  );
}